import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

type Props = {
  className?: string;
  size?: number;
};

const SpinnerLoader = (props: Props) => {
  const { className, size } = props;
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: size ?? 48, color: "gray" }}
            spin
          />
        }
      />
    </div>
  );
};

export default SpinnerLoader;
