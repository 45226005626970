import { Alert } from "antd";
import axios from "axios";
import * as qs from "qs";
import { useEffect, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Wix(props: Props) {
  const { onComplete, clientSecret } = props;
  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const [username, setUsername] = useState(isSandbox ? "user_good" : "");
  const [password, setPassword] = useState(isSandbox ? "pass_good" : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const org = stateStore.get("organization");

  useEffect(() => {
    if (isSandbox) {
      handleFakeComplete();
    } else {
      handleComplete();
    }
  });

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleFakeComplete = () => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.WIX,
        clientId: clientSecret,
        username: username,
        password: password,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = async () => {
    setLoading(true);
    persistLinkState(STORES.WIX, stateStore.getState());
    try {
      const q = qs.stringify({
        ...extractLinkParamsFromState(stateStore),
      });
      const url = `${getBackendUrl()}/wix/proxy/${org!.id}/app-url?${q}`;
      persistLinkState(STORES.WIX, stateStore.getState());
      window.location.replace(url);
    } catch (e) {
      handleAuthError(e);
      setLoading(false);
      return;
    }
  };

  return (
    <>
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </>
  );
}

export default Wix;
