import { Alert } from "antd";
import axios from "axios";
import qs from "query-string";
import { useEffect, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Etsy(props: Props) {
  const { onComplete, clientSecret } = props;
  const [error, setError] = useState("");
  const store = Store.useStore();
  const isSandbox = store.get("isSandbox");
  const organizationId = store.get("orgId");

  useEffect(() => {
    if (isSandbox) {
      handleFakeComplete();
    } else {
      handleComplete();
    }
  }, []);

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleComplete = () => {
    const q = qs.stringify({
      ...extractLinkParamsFromState(store),
    });
    persistLinkState(STORES.ETSY, store.getState());
    const url = `${getBackendUrl()}/etsy/proxy/${organizationId}/app-url?${q}`;
    window.location.replace(url);
  };

  const handleFakeComplete = () => {
    // skip oauth and get fake credentials
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.SQUARE,
        clientId: clientSecret,
        username: "user_good",
        password: "pass_good",
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError);
  };

  return (
    <>
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </>
  );
}

export default Etsy;
