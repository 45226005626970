import { Button, Input, Tag } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import ConnectionErrors, {
  CheckpointResponse,
  SCOPE_RESULT,
} from "../../components/ConnectionErrors";
import Store from "../../stateStore";

type FakeAuthForm = {
  username: string;
  password: string;
};

const VALID_USERNAME = "user_good";
const VALID_PASSWORD = "pass_good";

export default function SandboxFakeAuth() {
  const stateStore = Store.useStore();
  const onComplete = stateStore.get("handleSandboxComplete");

  const [loading, setLoading] = useState(false);
  const [validationResponse, setValidationResponse] = useState<
    CheckpointResponse[]
  >([]);

  const errorRef = React.useRef<HTMLInputElement>(null);

  const handleAuthError = () => {
    setValidationResponse([
      {
        checkpoint: "Creating Connection",
        result: SCOPE_RESULT.Failure,
        message: `Invalid username and password. Use ${VALID_USERNAME} and ${VALID_PASSWORD} to authenticate in sandbox mode.`,
        code: 400,
      },
    ]);
    errorRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const validate = (values: FakeAuthForm) => {
    const errors: any = {};
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const submitForm = async (values: FakeAuthForm) => {
    if (
      values.username != VALID_USERNAME ||
      values.password != VALID_PASSWORD
    ) {
      return handleAuthError();
    }
    setLoading(true);
    await onComplete();
    setLoading(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Title level={2}>Sandbox OAuth Login</Title>
      <Paragraph style={{ width: "70%" }}>
        This is a test OAuth authentication page. When not using sandbox mode,
        you will be taken to the authentication page for the respective
        platform.
      </Paragraph>
      <Formik
        initialValues={
          {
            username: VALID_USERNAME,
            password: VALID_PASSWORD,
          } as FakeAuthForm
        }
        validate={validate}
        onSubmit={submitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} style={{ width: "70%" }}>
            <div className="font-semibold">Admin Username</div>
            <div style={{ marginBottom: 16 }} className="mt-2">
              <Field placeholder="" name="username" as={Input} />
              {errors.username && touched.username && (
                <div className="mt-2">
                  <Tag color="orange">{errors.username}</Tag>
                </div>
              )}
            </div>
            <div className="font-semibold">Admin Password</div>
            <div style={{ marginBottom: 16 }} className="mt-2">
              <Field
                placeholder=""
                type="password"
                name="password"
                as={Input}
              />
              {errors.password && touched.password && (
                <div className="mt-2">
                  <Tag color="orange">{errors.password}</Tag>
                </div>
              )}
            </div>

            <Button disabled={loading} htmlType="submit">
              {loading ? "Connecting..." : "Sandbox: Connect Store"}
            </Button>
            <div ref={errorRef}>
              <ConnectionErrors
                platform={"Sandbox"}
                errors={validationResponse}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
