import * as amplitude from "@amplitude/analytics-browser";
import { Button } from "antd";
import axios from "axios";
import qs from "query-string";
import { useState } from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { useHistory } from "react-router";
import { IntuitBankFeedsConsentHeader } from "../../components/intuitBankFeeds/header";
import { HowIsYourDataUsed } from "../../components/intuitBankFeeds/howIsYourDataUsed";
import SpinnerLoading from "../../components/Loading/SpinnerLoader";
import { getBackendUrl } from "../../utils";
import IntuitBankFeedsCommonLayout, {
  LOCAL_STORAGE_ORG_INFO,
  OrgInfo,
} from "./IntuitBankFeedsCommon";

const IntuitBankFeedsConsent: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const { search } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { consent_challenge } = parsedSearch;

  const [orgInfo] = useState<OrgInfo>(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_ORG_INFO) ?? "{}"),
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleCompleteCancel = () => {
    amplitude.track("Intuit Bank Feeds: Consent Cancelled");
    history.push({
      pathname: "/intuit_bank_feeds_cancelled",
      state: { orgInfo },
    });
  };

  return (
    <IntuitBankFeedsCommonLayout errorMessage={errorMessage}>
      {isSuccess ? (
        <>
          <div className="flex justify-center items-center gap-3 mb-[16px]">
            <IoCheckmarkCircleOutline size={64} className="text-green-500" />
          </div>
          <h1 className="font-bold text-xl text-[#212430] text-center mb-[8px]">
            Success!
          </h1>
          <div
            className="text-center mt-1 font-thin text-slate-500"
            style={{ fontWeight: 300 }}
          >
            You have successfully logged in. We're directing you back to Intuit
            to complete the linking process.
          </div>
          <div className="h-24 flex items-center justify-center mt-5">
            <SpinnerLoading size={60} />
          </div>
        </>
      ) : (
        <ShareYourAccount
          consent_challenge={consent_challenge}
          handleCancel={handleCompleteCancel}
          orgInfo={orgInfo}
          updateIsSuccessState={setIsSuccess}
          updateErrorMessage={setErrorMessage}
        />
      )}
    </IntuitBankFeedsCommonLayout>
  );
};

const ShareYourAccount = ({
  orgInfo,
  handleCancel,
  consent_challenge,
  updateIsSuccessState,
  updateErrorMessage,
}: {
  orgInfo?: OrgInfo;
  handleCancel: () => void;
  consent_challenge: string;
  updateIsSuccessState: React.Dispatch<React.SetStateAction<boolean>>;
  updateErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleContinue = async () => {
    amplitude.track("Intuit Bank Feeds: Consent Continue Clicked");
    setIsLoading(true);

    try {
      updateIsSuccessState(true);

      const response = await axios.post(
        `${getBackendUrl()}/intuit_bank_feeds_connector/auth/consent?challenge=${consent_challenge}`,
        {
          scope: ["accounts", "transactions"],
        },
      );
      amplitude.track(
        "Intuit Bank Feeds: Consent Successful, continuing to success page",
      );

      window.location.replace(response.data);
    } catch (e: any) {
      amplitude.track("Intuit Bank Feeds: Consent Error");
      updateErrorMessage(
        e.response?.data?.error_message ?? "Something went wrong.",
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <IntuitBankFeedsConsentHeader
        title={`Share your ${orgInfo?.name} accounts with Intuit`}
        description="Intuit will gain access to the following data until you withdraw your
  consent. You can unlink your account through Intuit at any time."
        financialInstitutionLogo={orgInfo?.companyLogoUrl ?? undefined}
      />

      <HowIsYourDataUsed
        title="Account Summary Information"
        description="Details about the account name, description, type and balances."
      />
      <HowIsYourDataUsed
        title="Transactions"
        description="Records of past transactions, including dates, amounts, merchants, and descriptions."
      />

      <Button
        className="
        mt-8 sm:mt-12
        flex 
        justify-center
        items-center 
        py-4 sm:py-5
        rounded-md
        font-medium
        bg-[#fff] border-slate-300 
        hover:bg-[#F9FAFC] 
        hover:border-[#212430] hover:text-[#212430]
        border-1.5 
      "
        block
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        className=" 
        flex 
        justify-center 
        items-center 
        py-4 sm:py-5 mt-2 mb-[32px]
        rounded-md 
        text-white
        font-medium
        bg-[#212430] 
        hover:bg-[#020211] hover:text-[#fff] hover:border-[#020211]
      "
        block
        disabled={isLoading}
        loading={isLoading}
        onClick={handleContinue}
      >
        Continue
      </Button>
    </>
  );
};

export default IntuitBankFeedsConsent;
