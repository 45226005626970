import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import axios from "axios";
import qs from "query-string";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Square(props: Props) {
  const { onComplete, clientSecret } = props;
  const stateStore = Store.useStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const store = Store.useStore();
  const isSandbox = store.get("isSandbox");
  const orgName = store.get("orgName");
  const org = store.get("organization");
  const autoPlatform = store.get("autoPlatform");

  useEffect(() => {
    if (shouldShowTextUnderConnect(org)) {
      return;
    }
    if (isSandbox) {
      openSandboxAuth();
    } else {
      handleComplete();
    }
  }, []);

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleComplete = () => {
    // redirect to oauth
    setLoading(true);
    persistLinkState(STORES.SQUARE, store.getState());
    const q = qs.stringify({
      ...extractLinkParamsFromState(stateStore),
    });
    const url = `${getBackendUrl()}/oauth/square/initiate?${q}`;
    window.location.replace(url);
  };

  const history = useHistory();

  const handleFakeComplete = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${getBackendUrl()}/link/token/fulfill`,
        {
          type: STORES.SQUARE,
          clientId: clientSecret,
          username: "user_good",
          password: "pass_good",
        },
      );
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    } catch (e: any) {
      handleAuthError(e);
    }
    setLoading(false);
  };

  const openSandboxAuth = () => {
    stateStore.set("handleSandboxComplete")(handleFakeComplete);
    history.push("/sandboxauth");
  };

  if (!shouldShowTextUnderConnect(org)) {
    return <></>;
  }

  return (
    <div style={{ padding: "1.6rem" }}>
      {!autoPlatform && (
        <div style={{ display: "flex" }} className="">
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        </div>
      )}
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/square-logo.png"
          alt=""
        />
      </div>
      <div className="text-center pb-4">
        Connect your Square account with {orgName} by clicking the button below.
      </div>
      {isSandbox ? (
        <Button
          type="primary"
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test Square"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect Square"}
        </Button>
      )}
      {shouldShowTextUnderConnect(org) && (
        <div className="mt-2 text-xs">
          <TextUnderConnect></TextUnderConnect>
        </div>
      )}

      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Square;
