import { Spin } from "antd";
import axios from "axios";
import qs from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Stripe(props: Props) {
  const { onComplete, clientSecret } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const store = Store.useStore();
  const isSandbox = store.get("isSandbox");
  const stateStore = Store.useStore();
  const history = useHistory();

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  React.useEffect(() => {
    if (isSandbox) {
      openSandboxAuth();
    } else {
      handleComplete();
    }
  }, []);
  const handleComplete = () => {
    // redirect to oauth
    setLoading(true);
    persistLinkState(STORES.STRIPE, store.getState());
    const q = qs.stringify({
      ...extractLinkParamsFromState(stateStore),
    });
    const url = `${getBackendUrl()}/stripe/internal/authorize?${q}`;
    window.location.replace(url);
  };

  const handleFakeComplete = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${getBackendUrl()}/link/token/fulfill`,
        {
          type: STORES.STRIPE,
          clientId: clientSecret,
          username: "user_good",
          password: "pass_good",
        },
      );
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    } catch (e: any) {
      handleAuthError(e);
    }
    setLoading(false);
  };

  const openSandboxAuth = () => {
    stateStore.set("handleSandboxComplete")(handleFakeComplete);
    history.push("/sandboxauth");
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin></Spin>
    </div>
  );
}

export default Stripe;
