import React, { useState } from "react";
import { Button, Tooltip, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";

interface CopyButtonProps {
  textToCopy: string;
  disabled?: boolean;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  textToCopy,
  disabled = false,
}) => {
  const [tooltipText, setTooltipText] = useState("Copy");

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      message.success("Text copied to clipboard");
      setTooltipText("Copied");
      setTimeout(() => setTooltipText("Copy"), 2000); // Reset after 2 seconds
    });
  };

  return (
    <Tooltip title={tooltipText}>
      <Button
        type="text"
        icon={<CopyOutlined />}
        onClick={handleCopy}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default CopyButton;
