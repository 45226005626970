import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Typography } from "antd";
import axios from "axios";
import qs from "query-string";
import { useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";

const { Title, Paragraph, Text, Link } = Typography;

type RecurlyProps = {
  clientSecret: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function Recurly({ clientSecret, onBack, onCancel, onComplete }: RecurlyProps) {
  const stateStore = Store.useStore();

  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const autoPlatform = stateStore.get("autoPlatform");
  const itemId = stateStore.get("itemId");
  const org = stateStore.get("organization");

  const [url, setUrl] = useState(isSandbox ? "shop_good" : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isManual, setManual] = useState(false);
  const [form] = Form.useForm();

  const handleAuthError = (e: any) => {
    captureException(e);
    const errmsg = e.response?.data?.error_message;
    if (errmsg) {
      setError(errmsg);
      if (errmsg.includes("manually")) {
        setManual(true);
      }
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const handleManual = (values: any) => {
    const query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
      ...extractLinkParamsFromState(stateStore),
    });

    persistLinkState(STORES.RECURLY, stateStore.getState());

    axios
      .post(`${getBackendUrl()}/recurly/manual-submit?${query}`, {
        privateApiKey: values.privateApiKey as string,
        url: url,
        organizationId: organizationId,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "50px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/recurly-logo.png"
          alt=""
        />
      </div>
      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter in your custom Recurly subdomain. If your subdomain is
        example.recurly.com, then enter in "example".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="https://"
          addonAfter=".recurly.com"
          placeholder="Store URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      <div className="font-semibold">API Key</div>
      <div className="text-sm ">
        <Link
          href="https://docs.recurly.com/docs/api-keys#find-or-generate-your-api-key"
          target="_blank"
        >
          Click here to learn how to generate API Keys.
        </Link>
      </div>
      <div style={{ marginBottom: 0 }} className="mt-2">
        <Form
          layout="vertical"
          form={form}
          style={{ paddingBottom: 0 }}
          onFinish={handleManual}
        >
          <Form.Item
            label="Private API Key"
            name="privateApiKey"
            rules={[{ required: true, message: "Private API Key required" }]}
          >
            <Input.Password placeholder="ck_afjfeij234234jk3fk32" />
          </Form.Item>
          <Form.Item>
            {error && (
              <Alert
                className="mb-4"
                type="error"
                message={"Error"}
                description={error}
                showIcon
              ></Alert>
            )}
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading ? "Connecting..." : "Finish"}
            </Button>
          </Form.Item>
        </Form>
      </div>

      {shouldShowTextUnderConnect(org) && (
        <div className="mt-2 text-xs">
          <TextUnderConnect></TextUnderConnect>
        </div>
      )}
      {error && !isManual && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Recurly;
