import * as amplitude from "@amplitude/analytics-browser";
import styled from "@emotion/styled";
import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NETSUITE_PAGE_TITLE } from "../netsuite/constants";
import { STORES } from "../utils";

interface Props {
  setStore: React.Dispatch<React.SetStateAction<STORES>>;
  platforms: any[];
}

const SelectPlatform = ({ setStore, platforms }: Props) => {
  const history = useHistory();
  const [shownPlatforms, setShownPlatforms] = useState(platforms) as any[];
  const [search, setSearch] = useState("");
  const fuse = new Fuse(platforms, {
    keys: ["name"],
    threshold: 0.2,
  });

  useEffect(() => {
    setShownPlatforms(platforms as any);
  }, [platforms]);

  useEffect(() => {
    if (search === "") {
      setShownPlatforms(platforms);
    }
  }, [platforms, search]);

  const searchPlatforms = (e: any) => {
    setSearch(e.target.value);
    if (search === "") {
      setShownPlatforms(platforms);
    } else {
      const result = fuse.search(search);
      setShownPlatforms(result.map((r: any) => r.item));
    }
  };

  const selectPlatform = (platform: any) => {
    // setStep((prev) => prev + 1);
    if (platform.name === "Shopify") {
      setStore(STORES.SHOPIFY);
      history.push("/shopify");
    } else if (platform.name === "BigCommerce") {
      setStore(STORES.BIG_COMMERCE);
      history.push("/bigcommerce");
    } else if (platform.name === "WooCommerce") {
      setStore(STORES.WOO_COMMERCE);
      history.push("/woocommerce");
    } else if (platform.name === "Squarespace") {
      history.push("/squarespace");
    } else if (platform.name === "Wix") {
      setStore(STORES.WIX);
      history.push("/wix");
    } else if (platform.name === "Magento") {
      setStore(STORES.MAGENTO);
      history.push("/magento");
    } else if (platform.name === "Amazon") {
      setStore(STORES.AMAZON);
      history.push("/amazon");
    } else if (platform.name === "Square") {
      setStore(STORES.SQUARE);
      history.push("/square");
    } else if (platform.name === "Ebay") {
      setStore(STORES.EBAY);
      history.push("/ebay");
    } else if (platform.name === "Etsy") {
      history.push("/etsy");
    } else if (
      platform.name === "PrestaShop" ||
      platform.name === "Prestashop"
    ) {
      history.push("/prestashop");
    } else if (platform.name === "Stripe") {
      history.push("/stripe");
    } else if (platform.name === "Paypal") {
      history.push("/paypal");
    } else if (platform.name === "Shopee") {
      setStore(STORES.SHOPEE);
      history.push("/shopee");
    } else if (platform.name === "Shoper") {
      history.push("/shoper");
    } else if (platform.name === "Shopware") {
      history.push("/shopware");
    } else if (platform.name === "Webflow") {
      history.push("/webflow");
    } else if (platform.name === "Freshbooks") {
      setStore(STORES.FRESHBOOKS);
      history.push("/freshbooks");
    } else if (platform.name === "Lazada") {
      setStore(STORES.LAZADA);
      history.push("/lazada");
    } else if (platform.name === "Quickbooks") {
      setStore(STORES.QUICKBOOKS);
      history.push("/quickbooks");
    } else if (platform.name === "Xero") {
      setStore(STORES.XERO);
      history.push("/xero");
    } else if (platform.name === "Recurly") {
      setStore(STORES.RECURLY);
      history.push("/recurly");
    } else if (platform.name === "MercadoLibre") {
      setStore(STORES.MERCADOLIBRE);
      history.push("/mercadoLibre");
    } else if (platform.name === "Netsuite") {
      setStore(STORES.NETSUITE);
      document.title = NETSUITE_PAGE_TITLE;
      history.push("/netsuite");
    } else if (platform.name === "SageBusinessCloud") {
      setStore(STORES.SAGE_BUSINESS_CLOUD);
      history.push("/sagebusinesscloud");
    } else if (platform.name === "Chargebee") {
      setStore(STORES.CHARGEBEE);
      history.push("/chargebee");
    } else if (platform.name === "SageIntacct") {
      setStore(STORES.SAGE_INTACCT);
      history.push("/sageintacct");
    } else if (platform.name === "Wave") {
      setStore(STORES.WAVE);
      history.push("/wave");
    } else if (platform.name === "Alibaba") {
      setStore(STORES.ALIBABA);
      history.push("/alibaba");
    } else if (platform.name === "Dynamics 365") {
      setStore(STORES.DYNAMICS365);
      history.push("/dynamics365");
    } else {
      // 8/24/2022 Add query parameters to allow refreshing/goback of the page and everything still works, for example chargify
      history.push(`/${platform.name.toLowerCase()}` + history.location.search);
    }
  };
  return (
    <>
      <div className="p-4">
        <div className="pt-4 text-lg font-bold">Select your platform</div>
      </div>
      <input
        autoFocus
        className="m-2 mx-4 w-full font-lg outline-none"
        type="text"
        placeholder="Search"
        value={search}
        onChange={searchPlatforms}
      />
      <div className="mt-2">
        {shownPlatforms.map((platform: any) => {
          return (
            <HoverBackground
              key={platform.id}
              onClick={() => {
                amplitude.track("Platform Selected");
                amplitude.setGroup("platform", platform.name);
                selectPlatform(platform);
              }}
              className="py-4 flex items-center"
              style={{ borderBottom: "1px solid #F2F2F2" }}
            >
              <div>
                <img
                  style={{ marginLeft: "1.6rem", height: "40px" }}
                  src={platform.logoUrl}
                  alt=""
                />
              </div>
              <div className="ml-3">{platform.name}</div>
            </HoverBackground>
          );
        })}
      </div>
    </>
  );
};

export default SelectPlatform;

const HoverBackground = styled.div`
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background-color: #f2f2f2;
  }
`;
