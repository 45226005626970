import { Button, Checkbox, Steps, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ConnectionErrors, {
  CheckpointResponse,
} from "../components/ConnectionErrors";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { STORES, extractLinkParamsFromState, getBackendUrl } from "../utils";

const { Link } = Typography;
const { Step } = Steps;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

function TokenBasedNetsuite(props: Props) {
  const { onComplete, clientSecret } = props;

  const [loading, setIsLoading] = React.useState(false);
  const [checkedFinalCheckBox, setCheckedFinalCheckBox] = React.useState(false);
  const [error, setError] = useState("");
  const store = Store.useStore();
  const nonce = store.get("nonce");
  const organizationName = store.get("orgName");
  const [windowOpened, setWindowOpened] = React.useState(false);
  const [changeRoleInstructionsEnabled, enableChangeRoleInstructions] =
    React.useState(false);
  const [validationResponse, setValidationResponse] = useState<
    CheckpointResponse[]
  >([]);
  const [currentStep, setCurrentStep] = useState(0);

  const errorRef = React.useRef<HTMLInputElement>(null);
  const stateStore = Store.useStore();
  const history = useHistory();
  const isSandbox = store.get("isSandbox");

  const onChangeFinalCheckbox = (e: CheckboxChangeEvent) => {
    setCheckedFinalCheckBox(e.target.checked);
  };

  useEffect(() => {
    if (isSandbox) {
      stateStore.set("handleSandboxComplete")(handleFakeComplete);
      history.push("/sandboxauth");
      return;
    }
    document.title = "Connect to your NetSuite Instance";
  }, []);

  const handleFakeComplete = async () => {
    try {
      const response = await axios.post(
        `${getBackendUrl()}/link/token/fulfill`,
        {
          type: STORES.NETSUITE,
          clientId: clientSecret,
          username: "user_good",
          password: "pass_good",
        },
      );
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    } catch (e: any) {
      handleAuthError(e);
    }
  };

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response.data.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  const handleContinue = () => {
    setIsLoading(true);
    try {
      const q = qs.stringify(extractLinkParamsFromState(store));
      const url = `${getBackendUrl()}/netsuite/proxy/app-url?${q}`;
      persistLinkState(STORES.NETSUITE, store.getState());
      window.location.replace(url);
    } catch (res: any) {
      setValidationResponse(res.response.data.results);
      errorRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const myPopup = (
    myURL: string,
    title: string,
    myWidth: number,
    myHeight: number,
  ) => {
    let left = (window.screen as any).width / 2;
    left = window.innerWidth;
    const top = 0;
    const myWindow = window.open(
      myURL,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        myWidth +
        ", height=" +
        myHeight +
        ", top=" +
        top +
        ", left=" +
        left,
    );
    if (myWindow) {
      setWindowOpened(true);
      const pollTimer = window.setInterval(function () {
        if (myWindow?.closed !== false) {
          // !== is required for compatibility with Opera
          window.clearInterval(pollTimer);
          setWindowOpened(false);
        }
      }, 200);
    }
  };

  const popupLinkGroup = (text: string, url: string) => {
    return (
      <Link
        onClick={() => {
          // depending on size of available screen, we may want to resize link window to make it smaller
          let width = window.screen.width - window.innerWidth;

          if (window.innerWidth === window.screen.width) {
            width = window.innerWidth / 2;
          }
          myPopup(url, "web", width, window.innerHeight);
        }}
      >
        {text}
      </Link>
    );
  };

  const ResolveSection = () => {
    return (
      <ResolveSectionWrapper>
        {currentStep != 0 ? (
          <span className="mr-4">
            <Button
              type="default"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Go Back
            </Button>
          </span>
        ) : null}
        {(!changeRoleInstructionsEnabled && currentStep != 2) ||
        (changeRoleInstructionsEnabled && currentStep != 4) ? (
          <Button
            type="primary"
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            Next
          </Button>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleContinue}
            disabled={loading || !checkedFinalCheckBox}
          >
            {loading ? "Connecting..." : "Continue"}
          </Button>
        )}
      </ResolveSectionWrapper>
    );
  };

  const renderInstallBundle = () => {
    return (
      <div>
        <div className="flex items-align mt-4">
          {`To allow ${organizationName} to access your accounting data, you must first install a bundle within your NetSuite account. The following instructions will guide you to install the following Bundle:`}
        </div>
        <div className="flex items-align list-disc mt-4">
          <ul>
            <li>&#x2022; ID: 451666</li>
            <li>&#x2022; Name: AccountLink</li>
          </ul>
        </div>
        <div className="flex items-align mt-4">
          {"1. Open the NetSuite"}&nbsp;
          {popupLinkGroup(
            "AccountLink Bundle",
            "https://system.netsuite.com/app/bundler/bundledetails.nl?sourcecompanyid=7500456&id=451666",
          )}
        </div>
        <div className="flex items-align mt-4">
          {"2. Click Install."}
        </div>
        <br></br>
        <div className="flex items-align mt-4">
          {
            "IMPORTANT: If there are any conflicts on the Bundle Installation page (like the screenshot below), please select the 'Replace Existing Object' Option. Otherwise, you may run into a 'Your NetSuite Bundle is outdated' error."
          }
        </div>
        <br></br>
        <img
          // style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/netsuite-bundle-replace-existing-object.png"
          alt=""
        />
        <br></br>
        <ResolveSection />
      </div>
    );
  };

  const renderEnableRequiredFeatures = () => {
    return (
      <div>
        <div className="flex items-align">
          {`Enabling the SuiteCloud features allows ${organizationName} to connect to your NetSuite account:`}
        </div>
        <div className="flex items-align mt-4">
          {"1. Open the NetSuite"}&nbsp;
          {popupLinkGroup(
            "Enable Features Page",
            "https://system.netsuite.com/app/setup/features.nl",
          )}
        </div>
        <div className="flex items-align mt-4">
          {`2. Navigate to the SuiteCloud tab`}
        </div>
        <div className="flex items-align mt-4">
          {`3. Enable the following features:`}
        </div>
        <div className="flex items-align list-disc mt-4">
          <ol>
            <li>&#x2022; SuiteBuilder - CUSTOM RECORDS</li>
            <li>&#x2022; SuiteScript - CLIENT SUITESCRIPT</li>
            <li>&#x2022; SuiteScript - SERVER SUITESCRIPT</li>
            <li>&#x2022; SuiteTalk (Web Services) - REST WEB SERVICES</li>
            <li>&#x2022; Manage Authentication - TOKEN-BASED AUTHENTICATION</li>
          </ol>
        </div>
        <div className="flex items-align mt-4">{`4. Click Save! `}</div>
        <ResolveSection />
      </div>
    );
  };

  const renderAddRoleToUser = () => {
    return (
      <div>
        <div className="flex items-align">
          {`Adding the AccountLink role to your user will allow ${organizationName} the appropriate access to your transactions.`}
        </div>
        <div className="flex items-align mt-4">
          {"1. Open the NetSuite"}&nbsp;
          {popupLinkGroup(
            "Employees Page",
            "https://system.netsuite.com/app/common/entity/employeelist.nl",
          )}
        </div>
        <div className="flex items-align mt-4">
          {
            "2. Find the row corresponding to your Administrator user, and click your name."
          }
        </div>
        <div className="flex items-align mt-4">
          {
            "3. Click Edit, then scroll down and click the Access tab of the table in the bottom half of the page."
          }
        </div>
        <div className="flex items-align mt-4">
          {
            "4. Add a row for the 'AccountLink — Transactions (view-only)' role to your user, and click Save."
          }
        </div>
        <ResolveSection />
      </div>
    );
  };

  const renderSwitchToAccountLinkRole = () => {
    return (
      <div>
        <div className="flex items-align">
          {
            "Switching your user to the AccountLink role ensures that we have all the permissions required to allow ${organizationName} access to your transactions."
          }
        </div>
        <div className="flex items-align mt-4">
          {
            "1. To access the role, you would need to first log out of your instance."
          }
        </div>
        <div className="flex items-align mt-4">
          {"2. Open the NetSuite"}&nbsp;
          {popupLinkGroup(
            "My Role Page",
            "https://system.netsuite.com/app/login/secure/myroles/myroles.nl",
          )}
        </div>
        <div className="flex items-align mt-4">
          {"3. Click on 'AccountLink — Transactions (view-only)'."}
        </div>
        <ResolveSection />
      </div>
    );
  };

  const renderConfirmSetup = () => {
    return (
      <div>
        <div className="flex items-align">
          {
            "Please confirm that you have successfully completed the following steps:"
          }
        </div>
        <div className="flex items-align list-disc mt-4">
          <ol>
            <li className="mb-2">
              1. 'AccountLink' Bundle (ID: 451666) has finished installation
              (Check the&nbsp;
              {popupLinkGroup(
                "Installed Bundles Page",
                "https://system.netsuite.com/app/bundler/bundlelist.nl?type=I",
              )}
              &nbsp;and wait for there to be a green checkmark under Status)
            </li>
            <li className="mb-2">
              2. Enabled the <strong>SuiteCloud</strong> features
            </li>
            {changeRoleInstructionsEnabled ? (
              <li>3. Switched user to AccountLink role</li>
            ) : null}
          </ol>
        </div>

        <div className="flex items-align list-disc mt-4">
          <Checkbox onChange={onChangeFinalCheckbox}>
            I have successfully followed all the steps
          </Checkbox>
        </div>

        <ResolveSection />
      </div>
    );
  };

  return (
    <NetSuiteWrapper>
      <div
        className="flex  flex-column"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://s3.amazonaws.com/cdn.hotglue.xyz/images/logos/netsuite.svg"
          alt=""
        />
      </div>

      <Steps current={currentStep} direction="vertical" className="mt-4">
        <Step
          title={`Install the AccountLink NetSuite Bundle`}
          status="process"
          description={currentStep === 0 ? renderInstallBundle() : null}
        />
        <Step
          title={`Enable required features`}
          status="process"
          description={
            currentStep === 1 ? renderEnableRequiredFeatures() : null
          }
        />
        {/* make this programmatic in the future */}
        {changeRoleInstructionsEnabled ? (
          <Step
            title={`Add AccountLink role to current user`}
            status="process"
            description={currentStep === 2 ? renderAddRoleToUser() : null}
          />
        ) : null}
        {changeRoleInstructionsEnabled ? (
          <Step
            title={`Switch over to AccountLink role`}
            status="process"
            description={
              currentStep === 3 ? renderSwitchToAccountLinkRole() : null
            }
          />
        ) : null}
        <Step
          title={`Confirm Setup`}
          status="process"
          description={
            (changeRoleInstructionsEnabled && currentStep === 4) ||
            (!changeRoleInstructionsEnabled && currentStep === 2)
              ? renderConfirmSetup()
              : null
          }
        />
      </Steps>
      <ErrorWrapper ref={errorRef}>
        <ConnectionErrors platform={"Netsuite"} errors={validationResponse} />
      </ErrorWrapper>
    </NetSuiteWrapper>
  );
}

const ErrorWrapper = styled.div`
  padding-bottom: 2rem;
`;

const NetSuiteWrapper = styled.div`
  padding: 1rem 4rem 200px;
  min-height: 100vh;
  max-width: 50rem;
`;

const ResolveSectionWrapper = styled.div`
  margin: 1rem 0rem;
`;

export default TokenBasedNetsuite;
