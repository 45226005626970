import axios from "axios";
import { useEffect, useState } from "react";
import { LINK_STATE_STORAGE_FIELD } from "../constants";
import { captureException } from "../sentry";
import { StoreType } from "../stateStore";
import { getBackendUrl, STORES } from "../utils";
import * as amplitude from "@amplitude/analytics-browser";

export type LocalStorageState = {
  currentPlatform: STORES;
  origin: string;
  nonce: string;
  orgId: string;
  orgName: string;
};

interface LinkTrackerProps {
  result: "success" | "failure";
}

function useLinkTracker({ result }: LinkTrackerProps) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      const data = localStorage.getItem(LINK_STATE_STORAGE_FIELD);
      if (!data) {
        return;
      }
      const localState = JSON.parse(data) as LocalStorageState;
      amplitude.setGroup("orgId", localState?.orgId);
      if (result === "success") {
        amplitude.track("Link Success");
      } else {
        amplitude.track("Link Failure");
      }
      axios
        .post(
          `/instrumentation/link/${result}`,
          {
            localState,
            platform: localState.currentPlatform,
          },
          {
            baseURL: getBackendUrl(),
            timeout: 5000,
          },
        )
        .then((response) => setData(response.data))
        .catch((error) => setError(error));
    } catch (e) {
      captureException(e);
    }
  }, []);

  return { trackingData: data, trackingError: error };
}

export function persistLinkState(
  platform: STORES,
  storeData: Readonly<StoreType>,
): void {
  const localStorageState: LocalStorageState = {
    currentPlatform: platform,
    origin: storeData.origin,
    nonce: storeData.nonce,
    orgId: storeData.orgId,
    orgName: storeData.orgName,
  };

  // Persist the state in local storage for access on redirect
  localStorage.setItem(
    LINK_STATE_STORAGE_FIELD,
    JSON.stringify(localStorageState),
  );
}

export { useLinkTracker };
