import { Alert } from "antd";
import qs from "query-string";
import { useEffect, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type SageBusinessCloudProps = {
  clientSecret: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function SageBusinessCloud({
  clientSecret,
  onBack,
  onCancel,
  onComplete,
}: SageBusinessCloudProps) {
  const store = Store.useStore();
  const origin = store.get("origin");
  const nonce = store.get("nonce");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    handleComplete();
  }, []);

  const handleComplete = async () => {
    try {
      persistLinkState(STORES.SAGE_BUSINESS_CLOUD, store.getState());
      const q = qs.stringify(extractLinkParamsFromState(store));
      const url = `${getBackendUrl()}/sagebusinesscloud/proxy/app-url?${q}`;
      persistLinkState(STORES.SAGE_BUSINESS_CLOUD, store.getState());
      window.location.replace(url);
    } catch (e) {
      handleAuthError(e);
    }
  };

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response.data.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  return (
    <>
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </>
  );
}

export default SageBusinessCloud;
