import { Button, Input } from "antd";
import qs from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router";
import IntuitBankFeedsCommonLayout from "./IntuitBankFeedsCommon";

const IntuitBankFeedsFakeLogin: React.FC = () => {
  const history = useHistory();
  const { search, pathname } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { redirect_uri } = parsedSearch;

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleCompleteSubmit = async () => {
    const redirectUriWithPassword = `${redirect_uri}&otp=${password}`;
    window.location.replace(redirectUriWithPassword);
  };

  return (
    <IntuitBankFeedsCommonLayout name={"OAUTH BANK LOGIN PAGE"}>
      <div className="mt-2">
        <Input
          placeholder="Username"
          value={username}
          title="Username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          placeholder="Password"
          type="password"
          value={password}
          title="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <Button
        className="mt-3"
        type="primary"
        block
        disabled={!password || !username}
        onClick={handleCompleteSubmit}>
        Submit
      </Button>
    </IntuitBankFeedsCommonLayout>
  );
};

export default IntuitBankFeedsFakeLogin;
