import * as amplitude from "@amplitude/analytics-browser";
import { Alert } from "antd";
import axios from "axios";
import qs from "query-string";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type QuickBooksProps = {
  clientSecret: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function QuickBooks({
  clientSecret,
  onBack,
  onCancel,
  onComplete,
}: QuickBooksProps) {
  const store = Store.useStore();
  const storeState = store.getState();

  const isSandbox = store.get("isSandbox");
  const [error, setError] = useState<string>("");
  const stateStore = Store.useStore();
  const history = useHistory();

  useEffect(() => {
    if (isSandbox) {
      stateStore.set("handleSandboxComplete")(handleFakeComplete);
      history.push("/sandboxauth");
      return;
    }
    amplitude.setGroup("platform", "QUICKBOOKS");
    handleComplete();
  }, []);

  const handleComplete = async () => {
    try {
      persistLinkState(STORES.QUICKBOOKS, stateStore.getState());
      const q = qs.stringify(extractLinkParamsFromState(store));
      const url = `${getBackendUrl()}/quickbooks/proxy/app-url?${q}`;
      window.location.replace(url);
    } catch (e) {
      handleAuthError(e);
    }
  };

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response.data.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  const handleFakeComplete = async () => {
    try {
      const response = await axios.post(
        `${getBackendUrl()}/link/token/fulfill`,
        {
          type: STORES.QUICKBOOKS,
          clientId: clientSecret,
          username: "user_good",
          password: "pass_good",
        },
      );
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    } catch (e: any) {
      handleAuthError(e);
    }
  };

  return (
    <>
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}{" "}
    </>
  );
}

export default QuickBooks;
