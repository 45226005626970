import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Modal, Steps, Typography } from "antd";
import axios from "axios";
import React, { useState } from "react";
import ConnectionErrors from "../components/ConnectionErrors";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl } from "../utils";

const { Text, Link, Title, Paragraph } = Typography;
const DEFAULT_IMAGE_WIDTH = 600;

const { Step } = Steps;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

function Shopify(props: Props) {
  const { onComplete } = props;

  const [loading, setIsLoading] = React.useState(false);
  const [website, setWebsite] = useState("");
  const [error, setError] = useState("");
  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const organizationName = stateStore.get("orgName");
  const shopifyStoreUrl = stateStore.get("shopifyStoreUrl");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const autoPlatform = stateStore.get("autoPlatform");
  const publicKey = stateStore.get("publicKey");
  const org = stateStore.get("organization");
  const [showPrivate, setShowPrivate] = React.useState(false);
  const [form] = Form.useForm();
  const [windowOpened, setWindowOpened] = React.useState(false);
  const [showFirstTimeAuth, setShowFirstTimeAuth] = React.useState(false);
  const [validationResponse, setValidationResponse] = useState([]);

  const errorRef = React.useRef<HTMLInputElement>(null);

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  function myPopup(
    myURL: string,
    title: string,
    myWidth: number,
    myHeight: number,
  ) {
    let left = (window.screen as any).width / 2;
    left = window.innerWidth;
    const top = 0;
    const myWindow = window.open(
      myURL,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        myWidth +
        ", height=" +
        myHeight +
        ", top=" +
        top +
        ", left=" +
        left,
    );
    if (myWindow) {
      setWindowOpened(true);
      const pollTimer = window.setInterval(function () {
        if (myWindow?.closed !== false) {
          // !== is required for compatibility with Opera
          window.clearInterval(pollTimer);
          setWindowOpened(false);
        }
      }, 200);
    }
  }

  const handleFinish = (values: any) => {
    const { apiKey, password } = values;
    // try to post to backend
    setIsLoading(true);
    axios
      .post(`${getBackendUrl()}/shopify/shopifyprivate`, {
        apiKey,
        password,
        publicKey: publicKey,
        storeName: shopifyStoreUrl,
        ...extractLinkParamsFromState(stateStore),
      })
      .then((res) => {
        const { data } = res;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch((e) => {
        const { checkPointResponse } = e.response.data;
        const setValidationResponseAndScroll = () => {
          setValidationResponse(checkPointResponse);
          errorRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        };
        checkPointResponse
          ? setValidationResponseAndScroll()
          : handleAuthError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderDescriptionForAppDetails = () => {
    return (
      <div>
        <div className="flex items-align">
          <div>App Name:</div>
          <div>
            <Text code copyable className="ml-2 items-center flex">
              {organizationName}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  const renderDescriptionForPermissions = () => {
    const permissionsMapping: any = {};
    const scopes =
      org?.privateShopifyScopes || "read_products,read_orders,read_customers";
    const splitScopes = scopes.split(",");
    const READ_ACCESS = "Read access";
    const READ_WRITE_ACCESS = "Read and write";
    let unMapped: string[] = [];
    splitScopes.forEach((scope) => {
      switch (scope) {
        case "read_products":
          permissionsMapping["Products"] = READ_ACCESS;
          break;
        case "write_products":
          permissionsMapping["Products"] = READ_WRITE_ACCESS;
          break;
        case "read_orders":
          permissionsMapping["Orders"] = READ_ACCESS;
          break;
        case "write_orders":
          permissionsMapping["Orders"] = READ_WRITE_ACCESS;
          break;
        case "read_customers":
          permissionsMapping["Customers"] = READ_ACCESS;
          break;
        case "write_customers":
          permissionsMapping["Customers"] = READ_WRITE_ACCESS;
          break;
        case "read_analytics":
          permissionsMapping["Analytics"] = READ_ACCESS;
          break;
        case "read_assigned_fulfillment_orders":
          permissionsMapping["Assigned fulfillment orders"] = READ_ACCESS;
          break;
        case "write_assigned_fulfillment_orders":
          permissionsMapping["Assigned fulfillment orders"] = READ_WRITE_ACCESS;
          break;
        case "read_inventory":
          permissionsMapping["Inventory"] = READ_ACCESS;
          break;
        case "write_inventory":
          permissionsMapping["Inventory"] = READ_WRITE_ACCESS;
          break;
        case "read_shopify_payments_payouts":
          permissionsMapping["Shopify Payments payouts"] = READ_ACCESS;
          break;
        case "read_order_edits":
          permissionsMapping["Order Editing"] = READ_ACCESS;
          break;
        case "write_order_edits":
          permissionsMapping["Order Editing"] = READ_WRITE_ACCESS;
          break;
        default:
          unMapped.push(scope);
          break;
      }
    });

    return (
      <div>
        {Object.keys(permissionsMapping).map((key) => {
          return (
            <div key={key} className="flex items-align">
              <div>{key}</div>
              <Text code className="ml-2 items-center flex">
                {permissionsMapping[key]}
              </Text>
            </div>
          );
        })}
        {unMapped.length > 0 && (
          <div className="flex items-align">
            <div>Additional:</div>
            {unMapped.map((scope) => {
              return (
                <Text code className="ml-1 items-center flex">
                  {scope}
                </Text>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const showEverything = windowOpened || form.isFieldsTouched();

  return (
    <div style={{ padding: "1.6rem 3rem 200px", minHeight: "100%" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex  flex-column"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/shopifylogo.png"
          alt=""
        />
      </div>
      <Modal
        style={{ flex: 1, justifyContent: "center" }}
        width={800}
        title="First Time Authenticate Instruction"
        visible={showFirstTimeAuth}
        onOk={() => setShowFirstTimeAuth(false)}
        onCancel={() => setShowFirstTimeAuth(false)}
      >
        <div>
          <Steps direction="vertical" className="mt-4">
            <Step
              title={`Click allow custom development`}
              status="process"
              description={
                <div className="flex items-align mt-4">
                  <img
                    width={DEFAULT_IMAGE_WIDTH}
                    src="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/b56129b3-1f23-4851-a6d3-751fca4e5b5b/Screen_Shot_2022-02-24_at_3.12.30_PM.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220308%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220308T064847Z&X-Amz-Expires=86400&X-Amz-Signature=7cddb117bd642747471ac1c12da6630ea62b673fc34e819aa06dd21a49896367&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22Screen%2520Shot%25202022-02-24%2520at%25203.12.30%2520PM.png%22&x-id=GetObject"
                    alt=""
                  />
                </div>
              }
            ></Step>
            <Step
              title={`Click allow custom app development again. The merchant warning is to enable you, as the store, to start installing the custom app. The custom app allows the merchant and authenticated partners to read/write data based on the scopes you will provide.`}
              status="process"
              description={
                <div className="flex items-align mt-4">
                  <img
                    width={DEFAULT_IMAGE_WIDTH}
                    src="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/a5b070db-91f0-47c2-a889-bf6f4d4a87a0/Screen_Shot_2022-02-24_at_3.19.15_PM.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220308%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220308T065156Z&X-Amz-Expires=86400&X-Amz-Signature=bbe50d1f9363e6b04f3acf9bbb91f7e916f5403f6b9b93915b3cc011fd794ad3&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22Screen%2520Shot%25202022-02-24%2520at%25203.19.15%2520PM.png%22&x-id=GetObject"
                    alt=""
                  />
                </div>
              }
            ></Step>
            <Step
              title={`Now you are brought to the create an App screen where you can follow the instructions based on the Rutter Link Flow`}
              status="process"
              description={
                <div className="flex items-align mt-4">
                  <img
                    width={DEFAULT_IMAGE_WIDTH}
                    src="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/da02c08f-24d8-478e-b2e5-303488c78fb1/Screen_Shot_2022-02-24_at_3.26.22_PM.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220308%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220308T065240Z&X-Amz-Expires=86400&X-Amz-Signature=38b0b89df26e417e9a185fdb8fc5dc5b6fd3c87fe151fa3c6df84cebe19f7ede&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22Screen%2520Shot%25202022-02-24%2520at%25203.26.22%2520PM.png%22&x-id=GetObject"
                    alt=""
                  />
                </div>
              }
            ></Step>
          </Steps>
        </div>
      </Modal>
      <Alert
        message={
          <div>
            <b>{organizationName}</b> does not currently have an app in the app
            store. To share your data with {organizationName}, follow the steps
            below to enable a custom app for {organizationName}.
          </div>
        }
        type="info"
      />

      <Steps
        current={windowOpened ? 1 : 0}
        direction="vertical"
        className="mt-4"
      >
        <Step
          title={
            <Link
              onClick={() => {
                // depending on size of available screen, we may want to resize link widnow to make it smaller
                let width = window.screen.width - window.innerWidth;

                if (window.innerWidth === window.screen.width) {
                  width = window.innerWidth / 2;
                }
                myPopup(
                  `https://${shopifyStoreUrl}/admin/apps/development`,
                  "web",
                  width,
                  window.innerHeight,
                );
              }}
            >
              Click here to go to your Custom App Development Page.
            </Link>
          }
          description="Make sure to allow custom app development in your store."
        />
        {showEverything && (
          <>
            <Step
              title={`Click "Create an app"`}
              status="process"
              description={
                <Link
                  onClick={() => {
                    setShowFirstTimeAuth(true);
                  }}
                >
                  Click here if you don't see "Create an app"
                </Link>
              }
            />
            <Step
              title={`Copy or select these values:`}
              status="process"
              description={renderDescriptionForAppDetails()}
            />
            <Step
              title={
                <div>
                  Click <b>Configure Admin API scopes</b>, add these
                  permissions:
                </div>
              }
              status="process"
              description={renderDescriptionForPermissions()}
            />
            <Step
              title={`Click "Save" to save the custom app.`}
              status="process"
            />
            <Step
              title={`Click "Install app" to active the custom app.`}
              status="process"
            />

            <Step
              title={`Reveal the Admin API Access Token and copy the token & API Key into the fields below (Please scroll down to the bottom to see input fields):`}
              status="process"
            />
            <div className="flex items-align mt-4">
              <img
                width={DEFAULT_IMAGE_WIDTH}
                src="https://public-76b1ec31-0e9c-412a-8530-c1eee6c81c20.s3.us-east-2.amazonaws.com/shopifyprivate_revealtoken.png"
                alt=""
              />
            </div>
            <div className="flex items-align mt-4">
              <img
                width={DEFAULT_IMAGE_WIDTH}
                src="https://public-76b1ec31-0e9c-412a-8530-c1eee6c81c20.s3.us-east-2.amazonaws.com/shopifyprivate_apikey.png"
                alt=""
              />
            </div>
            <Form
              layout="vertical"
              form={form}
              style={{ paddingBottom: 40 }}
              onFinish={handleFinish}
            >
              <Form.Item label="Store URL" name="storeUrl" rules={[{}]}>
                <div>{shopifyStoreUrl}</div>
              </Form.Item>
              <Form.Item
                label="Admin API access token"
                name="password"
                rules={[{ required: true, message: "Password required" }]}
              >
                <Input.Password placeholder="***********************" />
              </Form.Item>
              <Form.Item
                label="API Key"
                name="apiKey"
                rules={[{ required: true, message: "API key required" }]}
              >
                <Input placeholder="111114e91e382357bc61b5e7067edd39" />
              </Form.Item>
              <Form.Item>
                {error && (
                  <Alert
                    className="mb-4"
                    type="error"
                    message={"Error"}
                    description={error}
                    showIcon
                  ></Alert>
                )}
                <div
                  ref={errorRef}
                  style={{ display: "flex", justifyContent: "center" }}
                  className=""
                >
                  <ConnectionErrors
                    platform={"Shopify"}
                    errors={validationResponse}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  {loading ? "Connecting..." : "Finish"}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Steps>
    </div>
  );
}

export default Shopify;
