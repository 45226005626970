import { getBackendUrl } from "../../../utils";

type Props = {
  queryParams: Object;
  setIsRedirecting: (value: boolean) => void;
};
function OauthHandler(props: Props) {
  const { queryParams, setIsRedirecting } = props;
  const url = `${getBackendUrl()}/authentication/proxy/app-url?${queryParams}`;
  setIsRedirecting(true);
  window.location.replace(url);
}

export default OauthHandler;
