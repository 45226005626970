import styled from "styled-components";

export enum SCOPE_RESULT {
  Success = "SUCCESS",
  Failure = "FAILURE",
  Unknown = "UNKNOWN",
}

export type CheckpointResponse = {
  checkpoint: string;
  result: SCOPE_RESULT;
  code?: number;
  message?: string;
  messageList?: string[];
};

type Props = {
  platform?: string;
  errors: CheckpointResponse[];
};

const ConnectionErrors = (props: Props) => {
  if (props.errors?.length === 0) {
    return null;
  }

  return (
    <div>
      <div>
        <h1 className="pt-4 text-center text-xl">
          {props.platform + " authentication" ?? "Authentication"} was
          unsuccessful.
        </h1>
        <p className="text-center">Please refer to the messages below:</p>
      </div>
      {props.errors.map((response: CheckpointResponse, idx: number) => {
        if (response.result === SCOPE_RESULT.Failure) {
          return (
            <div className="my-2" key={idx}>
              <ErrorCard>
                <ErrorCardHeader>
                  <ErrorCardTitle>{response.checkpoint}</ErrorCardTitle>
                  {response.code ? (
                    <ErrorCode color="orange">{response.code}</ErrorCode>
                  ) : null}
                </ErrorCardHeader>
                <ErrorCardBody>
                  {response.message ? response.message : ""}
                  {response.messageList ? (
                    <ul>
                      {response.messageList.map((item, key) => (
                        <li key={key}>&#8226; {item}</li>
                      ))}
                    </ul>
                  ) : null}
                </ErrorCardBody>
              </ErrorCard>
            </div>
          );
        }
      })}
    </div>
  );
};

const ErrorCard = styled.div`
  border: 1px solid #e8e8e8;
  padding: 0.75rem;
  border-radius: 6px;
`;
const ErrorCardHeader = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ErrorCardTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;
const ErrorCardBody = styled.div`
  font-size: 0.8rem;
  padding: 0.25rem;
  // background-color: #f2f2f2;
  // padding: 0.5rem;
`;

const ErrorCode = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.1rem;
  color: ${(props) => props.color};
`;

export default ConnectionErrors;
