import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Input, Typography } from "antd";
import axios from "axios";
import qs from "qs";
import { useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

const { Text, Link, Title, Paragraph } = Typography;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Chargebee(props: Props) {
  const { onComplete, clientSecret } = props;

  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const org = stateStore.get("organization");
  const itemId = stateStore.get("itemId");
  const autoPlatform = stateStore.get("autoPlatform");

  const [storeName, setStoreName] = useState(isSandbox ? "shop_good" : "");
  const [apiKey, setApiKey] = useState(isSandbox ? "key_good" : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleFakeComplete = () => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.CHARGEBEE,
        clientId: clientSecret,
        apiKey: apiKey,
        storeName: storeName,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = () => {
    setLoading(true);

    if (!storeName) {
      setError("Please enter a valid Chargebee domain.");
      return;
    }

    if (!apiKey) {
      setError("Please enter a valid Chargebee API Key.");
      return;
    }

    if (storeName.includes("chargebee.com")) {
      setError("Please only input the store name and remove .chargebee.com");
      return;
    }

    let query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
      ...extractLinkParamsFromState(stateStore),
    });

    if (query !== "") {
      query = "?" + query;
    }

    persistLinkState(STORES.CHARGEBEE, stateStore.getState());

    axios
      .post(`${getBackendUrl()}/chargebee/verify-credential${query}`, {
        apiKey: apiKey,
        storeName: storeName,
        organizationId: organizationId,
        ...extractLinkParamsFromState(stateStore),
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/chargebee-logo.png"
          alt=""
        />
      </div>
      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter in your custom Chargebee subdomain. If your subdomain is
        example.chargebee.com, then enter in "example".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="https://"
          addonAfter=".chargebee.com"
          placeholder="Store Name"
          value={storeName}
          onChange={(e) => setStoreName(e.target.value)}
        />
      </div>
      <div className="font-semibold">Auth Key / Access Key</div>
      <div style={{ overflowWrap: "break-word" }}>
        Please provide a read-only API Key from the Chargebee admin portal.{" "}
        <Link
          href="https://www.chargebee.com/docs/2.0/api_keys.html#types-of-api-keys_read-only-key"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to find instructions on how to make a read-only API key.{" "}
        </Link>
        Make sure you make a "Read-only: All" API key and not a restricted one.
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder=""
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
      </div>
      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test Chargebee"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading || apiKey === "" || storeName === ""}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect Chargebee"}
        </Button>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Chargebee;
