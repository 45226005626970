import { Select } from "antd";
import React, { useMemo } from "react";
import { scrapingBeeSupportedCountries } from "./supportedCountries";

const { Option } = Select;

const CountryPicker = (props: { handleChange: (value: string) => void }) => {
  const countryNames = useMemo(
    () =>
      new Set(scrapingBeeSupportedCountries.map((c) => c.name.toLowerCase())),
    [],
  );
  return (
    <Select
      style={{ width: 200 }}
      placeholder="Select a country"
      optionFilterProp="children"
      onChange={props.handleChange}
      filterOption={(input, option) => countryNames.has(input.toLowerCase())}>
      {scrapingBeeSupportedCountries.map((country) => (
        <Option key={country.code} value={country.code}>
          {country.name}
        </Option>
      ))}
    </Select>
  );
};

export { CountryPicker };
