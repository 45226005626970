import { Button, Input } from "antd";
import qs from "query-string";
import { useState } from "react";
import { useHistory } from "react-router";
import { getBackendUrl } from "../utils";

type Props = {};

function SageBankFeedsAuth(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const { search } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { otpInstructions, companyLogoUrl, name, errorMessage } = parsedSearch;
  const [password, setPassword] = useState("");

  const handleComplete = () => {
    setIsLoading(true);
    const { state } = parsedSearch;
    const q = qs.stringify({
      state,
      password,
    });
    window.location.href = `${getBackendUrl()}/sageBankFeedsConnector/auth-otp?${q}`;
    setIsLoading(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 600 }}>
        <div className="flex flex-row items-center">
          <div className="flex mr-4">
            <img
              src={companyLogoUrl}
              alt="logo"
              style={{ width: 60, height: 60 }}
            />
          </div>
          <div className="flex font-semibold text-xl">{name ?? "Pluto"}</div>
        </div>
        <div className="mt-4 font-semibold" style={{ fontSize: "2.25rem" }}>
          {"Connect your bank"}
        </div>

        <div className="text-lg mt-4" style={{}}>
          One-Time Password (OTP)
        </div>
        <div className="mt-1 font-thin" style={{ fontWeight: 300 }}>
          {otpInstructions ??
            "Please input the one-time password (OTP) that was provided by " +
              name}
          .
        </div>
        <div className="mt-2">
          <Input
            placeholder=""
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div
          className="mt-1 font-thin tahiti"
          style={{ fontWeight: 200, color: "red" }}
        >
          {errorMessage}
        </div>
        <Button
          className="mt-3"
          type="primary"
          block
          disabled={isLoading || !password}
          onClick={handleComplete}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default SageBankFeedsAuth;
