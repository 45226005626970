import axios from "axios";
import { getBackendUrl } from "../../../utils";

type Props = {
  params: Object;
  onComplete: (publicToken: string) => void;
  handleAuthError: (e: any) => void;
  setIsLoading: (loading: boolean) => void;
};
function UserCredentialHandler(props: Props) {
  const { params, onComplete, handleAuthError, setIsLoading } = props;

  axios
    .post(`${getBackendUrl()}/authentication/verify-credential`, params)
    .then((response) => {
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    })
    .catch(handleAuthError)
    .finally(() => {
      setIsLoading(false);
    });
}

export default UserCredentialHandler;
