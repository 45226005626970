import qs from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router";
import IntuitBankFeedsCommonLayout, {
  LOCAL_STORAGE_ORG_INFO,
  OrgInfo,
} from "./IntuitBankFeedsCommon";

/**
 * This page is just for local testing purposes. In the production auth flow,
 * the user will be redirect to an Intuit page after the consent page.
 */
const IntuitBankFeedsSuccess: React.FC = () => {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { code, scope } = parsedSearch;
  const [orgInfo] = useState<OrgInfo>(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_ORG_INFO) ?? "{}"),
  );
  return (
    <IntuitBankFeedsCommonLayout
      companyLogoUrl={orgInfo.companyLogoUrl}
      name={orgInfo.name}
    >
      <div className="mt-4 font-semibold" style={{ fontSize: "2.25rem" }}>
        Authorization successful!
      </div>
      <div className="text-lg mt-4">Your code: {code}</div>
      <div className="text-lg mt-4">Valid scopes: {scope}</div>
    </IntuitBankFeedsCommonLayout>
  );
};

export default IntuitBankFeedsSuccess;
