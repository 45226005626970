import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Input } from "antd";
import axios from "axios";
import * as qs from "qs";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import {
  extractLinkParamsFromState,
  getBackButtonCallback,
  getBackendUrl,
  showBackButton,
  STORES,
} from "../utils";
import PlatformPageText from "./shared/PlatformPageText";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

function Shopify(props: Props) {
  amplitude.setGroup("platform", "SHOPIFY");

  const { onComplete, clientSecret } = props;

  const [loading, setIsLoading] = React.useState(false);
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const [error, setError] = useState("");
  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const org = stateStore.get("organization");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const orgName = stateStore.get("orgName");
  const history = useHistory();
  const isUpdate = stateStore.get("isUpdate");
  const autoPlatform = stateStore.get("autoPlatform");
  const returnUrl = stateStore.get("customReturnUrl");
  const itemId = stateStore.get("itemId");
  const autoStoreName = stateStore.get("autoShopifyStore"); // legacy
  const storeName = stateStore.get("platformData").storeName;
  const autoLoad = stateStore.get("autoLoad");
  const savedStoreName = storeName ?? autoStoreName;
  const [website, setWebsite] = useState(savedStoreName ? savedStoreName : "");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleComplete = async () => {
    // validate this
    if (website.endsWith(".")) {
      setError("Please remove the trailing dot at the end of the store name");
      return;
    }
    if (website.startsWith("http")) {
      setError(
        "Please only input the store name and remove the http:// or https:// at the front",
      );
      return;
    }
    if (website.includes("myshopify.com")) {
      setError("Please only input the store name and remove .myshopify.com");
      return;
    }

    const store = `${website}.myshopify.com`;
    try {
      await axios.get(`${getBackendUrl()}/shopify/store/${website}`);
    } catch (e) {
      setError(
        `${store} appears to be an invalid subdomain. Please ensure that the subdomain is correctly spelled.`,
      );
      return;
    }
    if (org?.isPrivateShopify) {
      stateStore.set("shopifyStoreUrl")(store);
      history.push("/shopifyprivate");
      return;
    }
    setIsLoading(true);
    persistLinkState(STORES.SHOPIFY, stateStore.getState());
    let result;
    try {
      const url = `${getBackendUrl()}/shopify/link/${organizationId}/generate-url`;
      result = await axios.get(url, {
        params: {
          store,
        },
      });
    } catch (e) {
      handleAuthError(e);
      setIsLoading(false);
      return;
    }
    const { type, url } = result.data;
    const query = qs.stringify({
      shop: store,
      linkorigin: origin,
      linknonce: nonce,
      linkupdate: isUpdate ? itemId : undefined,
      ...extractLinkParamsFromState(stateStore),
    });
    let redirectUrl;
    if (type === "PROXY") {
      redirectUrl = `${url}?${query}`;
    } else {
      redirectUrl = url;
      // also need to save some data to localstorage probably
      localStorage.setItem(
        "rutterlinkstate",
        JSON.stringify({
          currentPlatform: "SHOPIFY",
          orgName,
          ...extractLinkParamsFromState(stateStore),
        }),
      );
    }
    setIsRedirecting(true);
    window.location.replace(redirectUrl);
  };

  const handleFakeComplete = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${getBackendUrl()}/link/token/fulfill`,
        {
          type: STORES.SHOPIFY,
          clientId: clientSecret,
          website: "shop_good",
        },
      );
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    } catch (e: any) {
      handleAuthError(e);
    }
    setIsLoading(false);
  };

  const openSandboxAuth = () => {
    stateStore.set("handleSandboxComplete")(handleFakeComplete);
    history.push("/sandboxauth");
  };

  if (autoLoad && storeName) {
    if (isSandbox) {
      openSandboxAuth();
    } else {
      handleComplete();
    }
    return null;
  }

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {showBackButton(autoPlatform, returnUrl) && (
          <Button
            onClick={() =>
              getBackButtonCallback(autoPlatform, props.onBack, returnUrl)
            }
            icon={<ArrowLeftOutlined />}
            className="flex items-center">
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}>
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/shopify-logo.png"
          alt=""
        />
      </div>

      {org?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText></PlatformPageText>
        </div>
      )}

      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter in your custom Shopify subdomain. If your subdomain is
        example.shopify.com, then enter in "example". If your Shopify admin URL
        is admin.shopify.com/store/example, then enter in "example".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="http://"
          addonAfter=".myshopify.com"
          placeholder="Store Name"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>
      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={openSandboxAuth}>
          {loading ? "Connecting..." : "Sandbox: Connect Test Shopify"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading || website === ""}
          onClick={handleComplete}>
          {loading
            ? isRedirecting
              ? "Redirecting to Shopify, please wait..."
              : "Connecting..."
            : "Connect Shopify"}
        </Button>
      )}

      {shouldShowTextUnderConnect(org) && (
        <div className="mt-2 text-xs">
          <TextUnderConnect></TextUnderConnect>
        </div>
      )}

      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon></Alert>
      )}
    </div>
  );
}

export default Shopify;
