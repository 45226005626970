import React from "react";
import { useLocation } from "react-router";
import IntuitBankFeedsCommonLayout, { OrgInfo } from "./IntuitBankFeedsCommon";

const IntuitBankFeedsCancelled: React.FC = () => {
  const location = useLocation<{ orgInfo: OrgInfo }>();
  const { orgInfo } = location.state || { orgInfo: {} as OrgInfo };

  return (
    <IntuitBankFeedsCommonLayout
      companyLogoUrl={orgInfo.companyLogoUrl}
      name={orgInfo.name}
    >
      <div className="mt-4 text-lg">
        You have cancelled the Intuit Bank Feeds authorization process. If you
        would like to try again, please restart the process from Intuit.
      </div>
    </IntuitBankFeedsCommonLayout>
  );
};

export default IntuitBankFeedsCancelled;
