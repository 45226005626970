import { Alert, Button, Select } from "antd";
import qs from "query-string";
import { useEffect, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type MercadoLibreProps = {
  clientSecret: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

export enum MERCADO_LIBRE_REGION {
  GLOBAL_SELLING = "GLOBAL_SELLING",
  MEXICO = "MEXICO",
  BRAZIL = "BRAZIL",
}

const MERCADO_LIBRE_REGION_HUMAN_READABLE = {
  GLOBAL_SELLING: "Global Selling",
  MEXICO: "Mexico",
  BRAZIL: "Brazil",
};

function MercadoLibre({
  clientSecret,
  onBack,
  onCancel,
  onComplete,
}: MercadoLibreProps) {
  const store = Store.useStore();
  const [error, setError] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response.data.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  function handleComplete() {
    try {
      persistLinkState(STORES.MERCADOLIBRE, store.getState());

      const q = qs.stringify({
        ...extractLinkParamsFromState(store),
        region,
      });
      const url = `${getBackendUrl()}/mercadoLibre/proxy/app-url?${q}`;
      window.location.replace(url);
    } catch (e) {
      handleAuthError(e);
    }
  }

  return (
    <>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/mercadolibre-logo.png"
          alt=""
        />
      </div>
      <div className="font-semibold">Select your region</div>
      <Select
        showSearch
        style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
        placeholder="Select region"
        onSelect={(val: any) => setRegion(val as string)}
        defaultValue={region ? region : undefined}
      >
        {Object.keys(MERCADO_LIBRE_REGION).map((region) => (
          <Select.Option key={region} value={region}>
            {(MERCADO_LIBRE_REGION_HUMAN_READABLE as any)[region]}
          </Select.Option>
        ))}
      </Select>
      <Button
        type="primary"
        block
        disabled={isLoading || !region}
        onClick={handleComplete}
      >
        Connect
      </Button>
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        />
      )}
    </>
  );
}

export default MercadoLibre;
