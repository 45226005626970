import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import axios from "axios";
import qs from "query-string";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { UNKNOWN_ERROR_MESSAGE } from "../../constants";
import { persistLinkState } from "../../hooks/useLinkTracker";
import { captureException } from "../../sentry";
import Store from "../../stateStore";
import {
  extractLinkParamsFromState,
  getBackButtonCallback,
  getBackendUrl,
  showBackButton,
} from "../../utils";
import PlatformPageText from "../shared/PlatformPageText";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "../shared/TextUnderConnect";
import OauthHandler from "./completeHandler/oauthHandler";
import UserCredentialHandler from "./completeHandler/userCredentialHandler";
import CustomSubdomainComponent from "./frontendComponents/customSubdomain";
import UserInputComponent from "./frontendComponents/userInput";
import UserSelectComponent from "./frontendComponents/userSelect";

type Props = {
  clientSecret: string;
  config: any;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function GenericRoute(props: Props) {
  const [loading, setIsLoading] = React.useState(false);
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const [error, setError] = useState<string>("");
  const store = Store.useStore();
  const org = store.get("organization");
  const autoPlatform = store.get("autoPlatform");
  const returnUrl = store.get("customReturnUrl");
  const isSandbox = store.get("isSandbox");
  const history = useHistory();
  const [userInputFields, setUserInputFields] = useState<{
    [key: string]: string;
  }>({});
  const config = props.config;
  const onComplete = props.onComplete;

  const handleAuth = async () => {
    if (isSandbox) {
      store.set("handleSandboxComplete")(handleFakeComplete);
      history.push("/sandboxauth");
      return;
    } else {
      handleComplete();
    }
  };

  const handleComplete = async () => {
    try {
      setIsLoading(true);
      persistLinkState(config.platform, store.getState());

      const userInputParams = Object.entries(userInputFields).map(
        ([fieldName, fieldValue]) => ({
          [fieldName]: fieldValue,
        }),
      );
      const params = {
        ...extractLinkParamsFromState(store),
        ...Object.assign({}, ...userInputParams), // Merge the userInputParams into a single object
        platform: config.platform,
      };
      const queryParams = qs.stringify(params);
      if (config.type === "UserCreds") {
        UserCredentialHandler({
          params,
          onComplete,
          handleAuthError,
          setIsLoading,
        });
      } else {
        OauthHandler({ queryParams, setIsRedirecting });
      }
    } catch (e) {
      handleAuthError(e);
      setIsLoading(false);
      return;
    }
  };

  const handleFakeComplete = async () => {
    try {
      const response = await axios.post(
        `${getBackendUrl()}/link/token/fulfill`,
        {
          type: config.platform,
          clientId: props.clientSecret,
          username: "user_good",
          password: "pass_good",
        },
      );
      const { data } = response;
      const { public_token } = data;
      onComplete(public_token);
    } catch (e: any) {
      handleAuthError(e);
    }
  };

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  if (
    config.frontendComponents === undefined ||
    config.frontendComponents.length === 0
  ) {
    handleAuth();
    return null; // Return null when no frontend components exist
  } else {
    return (
      <div style={{ padding: "1.6rem" }}>
        <div style={{ display: "flex" }} className="">
          {showBackButton(autoPlatform, returnUrl) && (
            <Button
              onClick={() =>
                getBackButtonCallback(autoPlatform, props.onBack, returnUrl)
              }
              icon={<ArrowLeftOutlined />}
              className="flex items-center"
            >
              Back
            </Button>
          )}
        </div>
        <div
          className="flex items-center flex-column mt-4"
          style={{ justifyContent: "center", paddingBottom: "20px" }}
        >
          <img
            style={{ height: "80px" }}
            src={config.iconUrl}
            alt="platform-logo"
          />
        </div>
        {org?.platformPageText && (
          <div className="mb-2 text-xs">
            <PlatformPageText></PlatformPageText>
          </div>
        )}
        {config.frontendComponents.map(
          (frontendComponent: any, index: number) => {
            if (frontendComponent.type === "CustomSubdomain") {
              return (
                <CustomSubdomainComponent
                  key={index}
                  config={frontendComponent}
                  userInputField={
                    userInputFields[frontendComponent.userInputFieldName]
                  }
                  setUserInputField={(value: string) =>
                    setUserInputFields((prevFields) => ({
                      ...prevFields,
                      [frontendComponent.userInputFieldName]: value,
                    }))
                  }
                />
              );
            } else if (frontendComponent.type === "UserInput") {
              return (
                <UserInputComponent
                  key={index}
                  config={frontendComponent}
                  userInputField={
                    userInputFields[frontendComponent.userInputFieldName]
                  }
                  setUserInputField={(value: string) =>
                    setUserInputFields((prevFields) => ({
                      ...prevFields,
                      [frontendComponent.userInputFieldName]: value,
                    }))
                  }
                />
              );
            } else if (frontendComponent.type === "UserSelect") {
              return (
                <UserSelectComponent
                  key={index}
                  config={frontendComponent}
                  setUserInputField={(value: string) =>
                    setUserInputFields((prevFields) => ({
                      ...prevFields,
                      [frontendComponent.userInputFieldName]: value,
                    }))
                  }
                />
              );
            }
            // Add conditions and components for other types of frontendComponents here
            return null;
          },
        )}
        <Button type="primary" block disabled={loading} onClick={handleAuth}>
          {loading
            ? isRedirecting
              ? "Redirecting to " +
                config.formattedPlatformName +
                ", please wait..."
              : "Connecting..."
            : "Connect " + config.formattedPlatformName}
        </Button>
        {shouldShowTextUnderConnect(org) && (
          <div className="mt-2 text-xs">
            <TextUnderConnect></TextUnderConnect>
          </div>
        )}
        {error && (
          <Alert
            className="mt-4"
            type="error"
            message={"Error"}
            description={error}
            showIcon
          ></Alert>
        )}
      </div>
    );
  }
}

export default GenericRoute;
