import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Tag, Typography } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import qs from "query-string";
import { useMemo, useState } from "react";
import * as Yup from "yup";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import {
  extractLinkParamsFromState,
  getBackButtonCallback,
  getBackendUrl,
  showBackButton,
  STORES,
} from "../utils";
import PlatformPageText from "./shared/PlatformPageText";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";

const { Link } = Typography;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function WooCommerce(props: Props) {
  const { onComplete, clientSecret } = props;

  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const autoPlatform = stateStore.get("autoPlatform");
  const itemId = stateStore.get("itemId");
  const org = stateStore.get("organization");
  const customUrl = stateStore.get("customRedirectUrl");

  const [url, setUrl] = useState(isSandbox ? "shop_good" : "");
  const [urlWarning, setUrlWarning] = useState("");
  const [username] = useState(isSandbox ? "user_good" : "");
  const [password] = useState(isSandbox ? "pass_good" : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isManual, setManual] = useState(false);
  const [form] = Form.useForm();
  const returnUrl = stateStore.get("customReturnUrl");

  const validateUrlChange = useMemo(
    () =>
      debounce((input) => {
        if (!Yup.string().url().isValidSync(input)) {
          if (!input.startsWith("http://") && !input.startsWith("https://")) {
            setUrlWarning("Missing prefix, e.g. http:// or https://");
          } else {
            setUrlWarning("URL is invalid");
          }
        } else {
          setUrlWarning("");
        }
      }, 500),
    [],
  );

  const handleUrlChange = (input: string) => {
    setUrl(input);
    validateUrlChange(input);
  };

  const handleAuthError = (e: any) => {
    captureException(e);
    const errmsg = e.response?.data?.error_message;
    if (errmsg) {
      setError(errmsg);
      if (errmsg.includes("manually")) {
        setManual(true);
      }
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const handleFakeComplete = () => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.WOO_COMMERCE,
        clientId: clientSecret,
        website: url,
        username: username,
        password: password,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = () => {
    setLoading(true);
    const cleanStoreUrl = url;
    const q = qs.stringify({
      org: organizationId,
      store_url: cleanStoreUrl,
      link_update_id: itemId ?? undefined,
      custom_redirect_url: customUrl,
    });
    persistLinkState(STORES.WOO_COMMERCE, stateStore.getState());
    axios
      .get(`${getBackendUrl()}/woocommerce/get-app-url?${q}`)
      .then(() => {
        const redirectUrl = `${getBackendUrl()}/woocommerce/app-url?${q}`;
        window.location.replace(redirectUrl);
      })
      .catch(handleAuthError);
  };

  const handleManual = (values: any) => {
    setLoading(true);
    const query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
      ...extractLinkParamsFromState(stateStore),
    });

    axios
      .post(`${getBackendUrl()}/woocommerce/manual-submit?${query}`, {
        consumerKey: values.consumerKey,
        consumerSecret: values.consumerSecret,
        url: url,
        organizationId: organizationId,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {showBackButton(autoPlatform, returnUrl) && (
          <Button
            onClick={() =>
              getBackButtonCallback(autoPlatform, props.onBack, returnUrl)
            }
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/woocommerce-logo.png"
          alt=""
        />
      </div>
      {org?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText></PlatformPageText>
        </div>
      )}
      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter the full&nbsp;
        <Link href="https://wordpress.com/home/" target="_blank">
          WooCommerce domain name&nbsp;
        </Link>
        (e.g. https://www.example.com). Be sure to include prefixes such
        as&nbsp;
        <Tag>https://</Tag>
      </div>
      <div style={{ marginTop: 16 }} className="font-semibold">
        Requirements
      </div>
      <div style={{ marginBottom: 16 }} className="text-sm">
        Please ensure your WooCommerce store is accessible
        <Link
          href="https://docs.woocommerce.com/document/woocommerce-rest-api/#section-1"
          target="_blank"
        >
          &nbsp;by following these requirements.
        </Link>
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          placeholder="https://example.com"
          value={url}
          onChange={(e) => handleUrlChange(e.target.value)}
        />
        {urlWarning && (
          <div className="mt-2">
            <Tag color="orange">{urlWarning}</Tag>
          </div>
        )}
      </div>
      {isManual ? (
        <>
          <div className="font-semibold">API Keys</div>
          <div className="text-sm ">
            <Link
              href="https://docs.woocommerce.com/document/woocommerce-rest-api/#section-2"
              target="_blank"
            >
              Click here to learn how to generate API Keys.
            </Link>
            &nbsp;To ensure proper permissions, please generate the keys for an
            Admin or Shop Manager user.
          </div>
          <div style={{ marginBottom: 0 }} className="mt-2">
            <Form
              layout="vertical"
              form={form}
              style={{ paddingBottom: 0 }}
              onFinish={handleManual}
              requiredMark={false}
            >
              <Form.Item
                label={<div className="font-semibold">Consumer Key</div>}
                name="consumerKey"
                rules={[
                  {
                    required: true,
                    message: (
                      <Tag className="mt-2" color="orange">
                        Consumer Key required
                      </Tag>
                    ),
                  },
                ]}
              >
                <Input.Password placeholder="ck_093f8b524c498ae0f9f25d64ba" />
              </Form.Item>
              <Form.Item
                label={<div className="font-semibold">Consumer Secret</div>}
                name="consumerSecret"
                rules={[
                  {
                    required: true,
                    message: (
                      <Tag className="mt-2" color="orange">
                        Consumer Secret required
                      </Tag>
                    ),
                  },
                ]}
              >
                <Input.Password placeholder="cs_217a8e350n942ea0f3f26d14de" />
              </Form.Item>
              <Form.Item>
                {error && (
                  <Alert
                    className="mb-4"
                    type="error"
                    message={"Error"}
                    description={error}
                    showIcon
                  ></Alert>
                )}
                <Button
                  style={{ marginTop: 8 }}
                  type="primary"
                  block
                  htmlType="submit"
                  disabled={loading || !url || !!urlWarning}
                >
                  {loading ? "Connecting..." : "Connect WooCommerce"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      ) : isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test WooCommerce"}
        </Button>
      ) : (
        <div className="flex flex-col items-center">
          <Button
            type="primary"
            block
            disabled={loading || url === ""}
            onClick={handleComplete}
          >
            {loading ? "Connecting..." : "Connect WooCommerce"}
          </Button>
          <div>
            <Link
              onClick={() => setManual(true)}
              className="flex items-center center text-center"
              style={{ marginTop: 12 }}
            >
              Click here to enter API Keys manually
            </Link>
          </div>
        </div>
      )}
      {shouldShowTextUnderConnect(org) && (
        <div className="mt-2 text-xs">
          <TextUnderConnect></TextUnderConnect>
        </div>
      )}
      {error && !isManual && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default WooCommerce;
