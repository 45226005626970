import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Input, Tag, Typography } from "antd";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import qs from "qs";
import React, { useState } from "react";
import * as Yup from "yup";
import ConnectionErrors, {
  CheckpointResponse,
  SCOPE_RESULT,
} from "../components/ConnectionErrors";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { getBackendUrl, STORES } from "../utils";

const { Link } = Typography;

type MagentoForm = {
  url: string;
  username: string;
  password: string;
  otp: string;
};

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Magento(props: Props) {
  const { onComplete, clientSecret } = props;

  const stateStore = Store.useStore();
  const storeState = stateStore.getState();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const itemId = stateStore.get("itemId");
  const autoPlatform = stateStore.get("autoPlatform");

  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationResponse, setValidationResponse] = useState<
    CheckpointResponse[]
  >([]);

  const errorRef = React.useRef<HTMLInputElement>(null);

  const handleAuthError = (e: any) => {
    captureException(e);
    setValidationResponse([
      {
        checkpoint: "Creating Connection",
        result: SCOPE_RESULT.Failure,
        message: e.response.data?.error_message ?? UNKNOWN_ERROR_MESSAGE,
        code: 400,
      },
    ]);
    errorRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  // A custom validation function. This must return an object
  // which keys are symmetrical to our values/initialValues
  const validate = (values: MagentoForm) => {
    const errors: any = {};
    if (!values.url) {
      errors.url = "Full Domain is Required";
    } else if (
      !Yup.string().url().isValidSync(values.url) &&
      values.url != "shop_good"
    ) {
      errors.url = "Url is not valid.";
    }

    if (!values.otp) {
      if (!values.username) {
        errors.username = "Username is required";
      } else if (values.username.length > 50) {
        errors.username = "Must be 50 characters or less";
      }

      if (!values.password) {
        errors.password = "Password is required";
      }
    }

    return errors;
  };

  const handleFakeComplete = (values: MagentoForm) => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.MAGENTO,
        clientId: clientSecret,
        website: values.url,
        username: values.username,
        password: values.password,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = (values: MagentoForm) => {
    setLoading(true);

    const query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
    });

    persistLinkState(STORES.MAGENTO, storeState);

    axios
      .post(`${getBackendUrl()}/magento/verify-credential?${query}`, {
        fullDomain: values.url,
        username: values.username,
        password: values.password,
        organizationId: organizationId,
        otp: values.otp,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch((res) => {
        setValidationResponse(res.response.data.results);
        errorRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitForm = async (values: MagentoForm) => {
    try {
      if (isSandbox) {
        handleFakeComplete(values);
      } else {
        handleComplete(values);
      }
    } catch {
      throw Error("Error submitting form");
    }
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button onClick={props.onBack} className="flex items-center">
            <ArrowLeftOutlined />
            Back
          </Button>
        )}
      </div>
      <h1></h1>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/magento-logo.png"
          alt=""
        />
      </div>
      <div className="font-semibold">Full Domain</div>
      <div>
        Please provide the full URL domain (EG: https://www.example.com/). Be
        sure to include https://.
      </div>
      <Formik
        initialValues={
          {
            url: isSandbox ? "shop_good" : "",
            username: isSandbox ? "user_good" : "",
            password: isSandbox ? "pass_good" : "",
            otp: "",
          } as MagentoForm
        }
        validate={validate}
        onSubmit={submitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div style={{ marginBottom: 16 }} className="mt-2">
              <Field placeholder="https://example.com" name="url" as={Input} />
              {errors.url && touched.url && (
                <div className="mt-2">
                  <Tag color="orange">{errors.url}</Tag>
                </div>
              )}
            </div>
            <div className="font-semibold">Admin Username</div>
            <div style={{ marginBottom: 16 }} className="mt-2">
              <Field placeholder="" name="username" as={Input} />
              {errors.username && touched.username && (
                <div className="mt-2">
                  <Tag color="orange">{errors.username}</Tag>
                </div>
              )}
            </div>
            <div className="font-semibold">Admin Password</div>
            <div style={{ marginBottom: 16 }} className="mt-2">
              <Field
                placeholder=""
                type="password"
                name="password"
                as={Input}
              />
              {errors.password && touched.password && (
                <div className="mt-2">
                  <Tag color="orange">{errors.password}</Tag>
                </div>
              )}
            </div>
            {showOtp ? (
              <>
                <div className="font-semibold">Access Token</div>
                <div style={{ marginBottom: 16, overflowWrap: "break-word" }}>
                  Please provide an Access Token from the Magento admin portal.{" "}
                  <Link
                    href="https://www.notion.so/rutterapi/Magento-2-2FA-Reauth-5540979e89854023bb6dc8dc08728e35"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here for instructions on how to make an Access Token.
                  </Link>
                </div>
                <div className="font-semibold">Access Token</div>
                <div style={{ marginBottom: 16 }} className="mt-2">
                  <Field placeholder="" type="text" name="otp" as={Input} />
                </div>
              </>
            ) : (
              <>
                <Link
                  onClick={() => setShowOtp(true)}
                  className="flex items-center center text-center"
                  style={{ marginBottom: 16 }}
                >
                  Click here to use alternative Integration method.
                </Link>
              </>
            )}

            {isSandbox ? (
              <Button disabled={loading} htmlType="submit">
                {loading ? "Connecting..." : "Sandbox: Connect Test Magento"}
              </Button>
            ) : (
              <button className="w-full" type="submit">
                <Button
                  block
                  disabled={
                    loading ||
                    isSubmitting ||
                    !_.isEmpty(errors) ||
                    Object.values(touched).every((v) => v === false)
                  }
                >
                  {loading ? "Connecting..." : "Connect Magento"}
                </Button>
              </button>
            )}
            <div ref={errorRef}>
              <ConnectionErrors
                platform={"Magento"}
                errors={validationResponse}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Magento;

const Container = styled.div``;
