import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import qs from "query-string";
import { useEffect, useState } from "react";
import Spinner from "../components/Loading/Spinner";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import PlatformPageText from "./shared/PlatformPageText";

type GumroadProps = {
  clientSecret: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

function Gumroad({ clientSecret, onBack, onCancel, onComplete }: GumroadProps) {
  const store = Store.useStore();

  const autoPlatform = store.get("autoPlatform");
  const organization = store.get("organization");
  const isSandbox = store.get("isSandbox");
  const organizationId = store.get("orgId");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setLoading(true);
    routeToGumroadLoginLink();
  }, []);

  const routeToGumroadLoginLink = async () => {
    try {
      persistLinkState(STORES.GUMROAD, store.getState());
      const q = qs.stringify(extractLinkParamsFromState(store));
      const url = `${getBackendUrl()}/gumroad/proxy/${organizationId}/app-url?${q}`;
      window.location.replace(url);
    } catch (e) {
      handleAuthError(e);
      setLoading(false);
    }
  };

  function handleAuthError(e: any) {
    captureException(e);
    if (e.response.data.error_message) {
      setError(e.response.data.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }

  function handleFakeComplete() {}

  async function handleComplete() {
    setLoading(true);
    await routeToGumroadLoginLink();
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }}>
        {!autoPlatform && (
          <Button
            onClick={onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: 30 }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/gumroad-logos.png"
          alt=""
        />
      </div>
      {organization?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText />
        </div>
      )}
      <Button
        type="primary"
        block
        disabled={loading}
        onClick={isSandbox ? handleFakeComplete : handleComplete}
      >
        {isSandbox ? "Sandbox: Connect Test Gumroad" : "Connect Gumroad"}
      </Button>
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        />
      )}
    </div>
  );
}

export default Gumroad;
