import { Button, Result } from "antd";
import qs from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import { useLinkTracker } from "./hooks/useLinkTracker";
import { captureException } from "./sentry";
import { handleClose } from "./utils";
import Store from "./stateStore";
import { PAYONEER_ORG_IDS, REVOLUT_ORG_IDS } from "./constants";

// Testing for Payoneer first because I am scared this will break something, e.g. postMessage does not fire fast enough
// and we close the window too quickly
// Preferred UX is the popup closes immediately but I want to roll out slowly
// Rollout - Parafin, Parker

function shouldCloseImmediatelyGate(config: { orgId?: string }) {
  let { orgId } = config;
  try {
    const localStorageOrgId = localStorage.getItem("rutterOrgId");
    orgId = localStorageOrgId ?? orgId;
  } catch (e) {
    captureException(e);
  }
  if (orgId && [...PAYONEER_ORG_IDS, ...REVOLUT_ORG_IDS].includes(orgId)) {
    return true;
  }

  return false;
}

function LinkComplete() {
  useLinkTracker({ result: "success" });
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch = qs.parse(search);

  const store = Store.useStore();

  const { origin, nonce, public_token } = parsedSearch;

  React.useEffect(() => {
    if (public_token && nonce && origin) {
      // This means it was opened via the popup method, via JS
      if (window?.opener?.opener) {
        // 2/19/2023, shopify started rediercting to success by opening a different tab, so we have to go 1 layer down
        // The opener of this new tab (made by shopify) is the Rutter popup, and the opener of that is the Rutter link JS
        try {
          window.opener.opener.postMessage(
            JSON.stringify({
              publicToken: public_token,
              nonce,
              type: "SUCCESS",
            }),
            origin,
          );
        } catch (e) {
          captureException(e);
        }
      }
      if (window?.opener) {
        try {
          window.opener.postMessage(
            JSON.stringify({
              publicToken: public_token,
              nonce,
              type: "SUCCESS",
            }),
            origin,
          );
        } catch (e) {
          captureException(e);
        }
      }
    }
    if (
      shouldCloseImmediatelyGate({
        orgId: store.get("orgId"),
      })
    ) {
      handleClose();
    }
  }, []);

  if (
    shouldCloseImmediatelyGate({
      orgId: store.get("orgId"),
    })
  ) {
    return <div>Closing...</div>;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Result
        status="success"
        title="Authentication Complete!"
        subTitle=""
        extra={[
          <Button type="primary" key="console" onClick={handleClose}>
            Close window
          </Button>,
        ]}
      />
    </div>
  );
}

export default LinkComplete;
