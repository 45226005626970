import { Input } from "antd";

type CustomSubdomainConfig = {
  type: string;
  domain: string;
  promptText: string;
  userInputFieldName?: string;
};
type CustomSubdomainComponentProps = {
  config: CustomSubdomainConfig;
  userInputField: string;
  setUserInputField: (value: string) => void;
};
function CustomSubdomainComponent(props: CustomSubdomainComponentProps) {
  const { config, userInputField, setUserInputField } = props;

  return (
    <div>
      <div className="font-semibold">Custom Subdomain</div>
      <div className="text-sm">
        Please enter in your custom subdomain. For example, if your domain is
        foo.
        {config.domain}, then enter in "foo".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="http://"
          addonAfter={"." + config.domain}
          placeholder={config.promptText}
          value={userInputField}
          onChange={(e) => setUserInputField(e.target.value)}
        />
      </div>
    </div>
  );
}
export default CustomSubdomainComponent;
