import { Spin } from "antd";

type Props = {};

const Spinner = (props: Props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin></Spin>
    </div>
  );
};

export default Spinner;
