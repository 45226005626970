import React from "react";

interface ArcadeProps {
  src: string;
  title: string;
}

// for use with https://www.arcade.software/
const Arcade: React.FC<ArcadeProps> = (props) => {
  const { src, title } = props;
  return (
    <div
      className="relative h-0 w-full"
      style={{
        paddingBottom: "calc(61.70355466130114% + 41px)",
      }}
    >
      <iframe
        src={src}
        title={title}
        frameBorder="0"
        loading="lazy"
        allowFullScreen
        allow="clipboard-write"
        className="absolute top-0 left-0"
        style={{
          width: "100%",
          height: "100%",
          colorScheme: "light",
        }}
      ></iframe>
    </div>
  );
};

export default Arcade;
