import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Input, Steps } from "antd";
import Link from "antd/lib/typography/Link";
import axios from "axios";
import qs from "qs";
import { useCallback, useState } from "react";
import { JARIS_ORG_ID, UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import PlatformPageText from "./shared/PlatformPageText";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";
import Arcade from "../components/Arcade";
import { FiDownload } from "react-icons/fi";
import CopyButton from "../components/CopyButton";

const { Step } = Steps;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

// todo(marko): add sandbox support for Sage Intacct
const Sage50 = (props: Props) => {
  const { onComplete } = props;

  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const store = Store.useStore();
  const autoPlatform = store.get("autoPlatform");
  const isSandbox = store.get("isSandbox");
  const organization = store.get("organization");
  const orgId = store.get("orgId");

  const itemId = store.get("itemId");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [companyId, setCompanyId] = useState("");
  const stateStore = Store.useStore();

  const handleComplete = async () => {
    setIsLoading(true);
    persistLinkState(STORES.SAGE_50, store.getState());
    // TODO implement verification
    onComplete("WFEF");
  };

  const handleDownloadConnector = () => {
    setIsLoading(true);
    axios
      .post(`${getBackendUrl()}/quickbooks-desktop/link`, {
        ...extractLinkParamsFromState(stateStore),
        link_update_id: itemId === "" ? stateStore.get("itemId") : itemId,
      })
      .then((res) => {
        const { data } = res;
        const { qwcContent, password } = data;
        const file = new File([qwcContent], "quickbooks.qwc", {
          type: "text/plain",
        });

        function download() {
          const link = document.createElement("a");
          const url = URL.createObjectURL(file);

          link.href =
            "https://rutterpublicimages.s3.us-east-2.amazonaws.com/Sage+50+Connector+Installer.zip";
          link.download = file.name;
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
        download();
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem", maxWidth: 800, minHeight: "100vh" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center">
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}>
        <img
          style={{ height: "80px" }}
          src="https://superspeed-ph.com/wp-content/uploads/2018/09/sage50-new.png"
          alt=""
        />
      </div>

      {organization?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText></PlatformPageText>
        </div>
      )}
      <Steps direction="vertical" className="mt-4">
        <Step
          title="Download the Sage50 Connector application."
          status="process"
          description={
            <>
              <Button
                className="flex gap-2 items-center"
                type="primary"
                onClick={handleDownloadConnector}
                disabled={false}>
                <FiDownload />
                Download the Sage 50 Connector.
              </Button>
            </>
          }
        />
        <Step
          title="Input Credentials During Setup"
          status="process"
          description={
            <>
              <div className="text-sm">
                Please enter your Sage Intacct Company ID, username, and
                password. Please make sure that this account has full
                permissions to all objects.
                <Link
                  target="_blank"
                  href="https://rutterapi.notion.site/Sage-Intacct-Create-Web-Service-User-54916c4bd12b4293add1004320fc323a">
                  {" "}
                  If you are creating a Web Service user
                </Link>
                , please make sure that the user or the user's associated role
                has full permissions to all objects.
              </div>
              <br></br>
              <div className="font-semibold">Company ID</div>
              <div style={{ marginBottom: 16 }} className="mt-2">
                <div className="border rounded shadow py-2 px-3 flex items-center justify-between cursor-not-allowed max-w-xl mb-2">
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap flex-grow">
                    {"4fa9ffc9-e8c5-4e85-a750-54241e1954ff" || " "}
                  </span>
                  <CopyButton
                    textToCopy={"4fa9ffc9-e8c5-4e85-a750-54241e1954ff"}
                  />
                </div>
              </div>
              <div className="font-semibold">Access Key</div>
              <div style={{ marginBottom: 16 }} className="mt-2">
                <div className="border rounded shadow py-2 px-3 flex items-center justify-between cursor-not-allowed max-w-xl mb-2">
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap flex-grow">
                    {"7e89db04-d9d3-4af3-882e-3d79e00b285b" || " "}
                  </span>
                  <CopyButton
                    textToCopy={"7e89db04-d9d3-4af3-882e-3d79e00b285b"}
                  />
                </div>
              </div>

              <Button type="primary" block onClick={handleComplete}>
                {loading ? "Connecting..." : `Verify Connection`}
              </Button>
              {shouldShowTextUnderConnect(organization) && (
                <div className="mt-2 text-xs">
                  <TextUnderConnect></TextUnderConnect>
                </div>
              )}
              {error && (
                <Alert
                  className="mt-4"
                  type="error"
                  message={"Error"}
                  description={error}
                  showIcon></Alert>
              )}
            </>
          }
        />
      </Steps>
    </div>
  );
};

export default Sage50;
