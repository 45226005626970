import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import axios from "axios";
import qs from "query-string";
import { useCallback, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import PlatformPageText from "./shared/PlatformPageText";

type WebflowProps = {
  clientSecret: string;
  onBack: () => void;
  onCancel: () => void;
  onComplete: (publicToken: string) => void;
};

const Webflow = ({
  clientSecret,
  onBack,
  onCancel,
  onComplete,
}: WebflowProps) => {
  const store = Store.useStore();
  const autoPlatform = store.get("autoPlatform");
  const isSandbox = store.get("isSandbox");
  const nonce = store.get("nonce");
  const org = store.get("organization");
  const origin = store.get("origin");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleAuthError = useCallback((e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }, []);

  const handleFakeComplete = useCallback(() => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.WEBFLOW,
        clientId: clientSecret,
        username: "user_good",
        password: "pass_good",
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  }, [clientSecret, handleAuthError, onComplete]);

  const handleComplete = useCallback(() => {
    setLoading(true);
    try {
      persistLinkState(STORES.WEBFLOW, store.getState());
      const q = qs.stringify(extractLinkParamsFromState(store));
      const url = `${getBackendUrl()}/webflow/proxy/${org!.id}/app-url?${q}`;
      window.location.replace(url);
    } catch (e) {
      handleAuthError(e);
      setLoading(false);
    }
  }, [handleAuthError, nonce, org, origin, store]);

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }}>
        {!autoPlatform && (
          <Button
            onClick={onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: 30 }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/webflow-logo.png"
          alt=""
        />
      </div>
      {org?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText />
        </div>
      )}
      <Button
        type="primary"
        block
        disabled={loading}
        onClick={isSandbox ? handleFakeComplete : handleComplete}
      >
        {isSandbox ? "Sandbox: Connect Test Webflow" : "Connect Webflow"}
      </Button>
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
};

export default Webflow;
