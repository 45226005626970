import BankSVG from "../../assets/bank.svg";

export const IntuitBankFeedsConsentHeader: React.FC<{
  title: string | React.ReactNode;
  description?: string;
  financialInstitutionLogo?: string;
}> = ({ financialInstitutionLogo, description, title }) => {
  const ImageContainer: React.FC = ({ children }) => (
    <div className="border-2 rounded-md p-2">{children}</div>
  );

  return (
    <>
      <div className="flex justify-center items-center gap-3 mb-[16px] mt-[24px]">
        {financialInstitutionLogo && (
          <>
            <ImageContainer>
              <img
                src={financialInstitutionLogo}
                alt="logo"
                className="w-5 sm:w-7 h-5 sm:h-7 max-w-[60px] max-h-[60px]"
              />
            </ImageContainer>

            <div className="flex gap-[3px]">
              <div className="w-[4px] sm:w-[5px] h-[4px] sm:h-[5px] rounded-full bg-slate-300" />
              <div className="w-[4px] sm:w-[5px] h-[4px] sm:h-[5px] rounded-full bg-slate-300" />
              <div className="w-[4px] sm:w-[5px] h-[4px] sm:h-[5px] rounded-full bg-slate-300" />
            </div>
          </>
        )}

        <ImageContainer>
          <img
            src={BankSVG}
            alt="generic bank image"
            className="w-5 sm:w-7 h-5 sm:h-7 max-w-[60px] max-h-[60px]"
          />
        </ImageContainer>
      </div>

      <h1
        className={`
          text-lg sm:text-xl  
          font-bold 
          text-[#212430] 
          text-center 
          leading-6 sm:leading-7 
          ${!description ? "mb-[24px]" : "mb-[8px]"} px-4
        `}
      >
        {title}
      </h1>
      {description && (
        <p className="text-slate-500 text-center text-xs sm:text-sm mb-[32px]">
          {description}
        </p>
      )}
    </>
  );
};
