import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Input, Typography } from "antd";
import axios from "axios";
import qs from "qs";
import { useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Shoplazza(props: Props) {
  const { onComplete, clientSecret } = props;

  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const org = stateStore.get("organization");
  const itemId = stateStore.get("itemId");
  const autoPlatform = stateStore.get("autoPlatform");

  const [storeName, setStoreName] = useState(isSandbox ? "shop_good" : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleFakeComplete = () => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.SHOPLAZZA,
        clientId: clientSecret,
        storeName: storeName,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = () => {
    setLoading(true);
    if (!storeName) {
      setError("Please enter a valid Shoplazza domain.");
      return;
    }

    if (storeName.includes("myshoplaza.com")) {
      setError("Please only input the store name and remove .myshoplaza.com");
      return;
    }

    let query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
      storeName: storeName ? storeName : undefined,
      ...extractLinkParamsFromState(stateStore),
    });

    if (query !== "") {
      query = "?" + query;
    }
    window.location.replace(
      `${getBackendUrl()}/shoplazza/proxy/app-url${query}`,
    );
    persistLinkState(STORES.SHOPLAZZA, stateStore.getState());
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/shoplazza-logo.png"
          alt=""
        />
      </div>
      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter in your custom Shoplazza store name. If your store name is
        example.myshoplaza.com, then enter in "example".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="https://"
          addonAfter=".myshoplaza.com"
          placeholder="Store Name"
          value={storeName}
          onChange={(e) => setStoreName(e.target.value)}
        />
      </div>
      <div className="font-semibold">Auth Key / Access Key</div>
      <div style={{ overflowWrap: "break-word" }}>
        Please provide a client id from the Shoplazza admin portal.{" "}
      </div>
      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test Shoplazza"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading || storeName === ""}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect Shoplazza"}
        </Button>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Shoplazza;
