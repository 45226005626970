import { Select } from "antd";
import Link from "antd/lib/typography/Link";

type UserSelectConfig = {
  type: string;
  title?: string;
  text?: string;
  link?: string;
  linkText?: string;
  promptText?: string;
  options: Record<string, string>;
  userInputFieldName: string;
};
type UserSelectComponentProps = {
  config: UserSelectConfig;
  setUserInputField: (value: string) => void;
};
function UserSelectComponent(props: UserSelectComponentProps) {
  const { config, setUserInputField } = props;
  let userFacingValue = "";

  return (
    <div>
      {config.title && <div className="font-semibold">{config.title}</div>}
      {config.text && <div className="text-sm ">{config.text}</div>}

      {config.linkText && config.link && (
        <div className="text-sm ">
          <Link href={config.link} target="_blank">
            {config.linkText}
          </Link>
        </div>
      )}
      {
        <div>
          <Select
            showSearch
            style={{ width: "100%", marginTop: 10, marginBottom: 16 }}
            placeholder={config.promptText}
            value={userFacingValue ? userFacingValue : undefined}
            onSelect={(key: any) => {
              setUserInputField(config.options[key]);
              userFacingValue = key;
            }}
          >
            {Object.keys(config.options).map((key) => (
              <Select.Option key={key} value={key}>
                {key}
              </Select.Option>
            ))}
          </Select>
        </div>
      }
    </div>
  );
}
export default UserSelectComponent;
