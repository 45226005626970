import React from "react";
import RutterSVG from "../../assets/rutter.svg";
import "./intuitBankFeeds.css";

export type TermsAndConditions = {
  defaultUrl: string;
  additionalUrl?: string;
  additionalUrlTitle?: string;
};

export interface OrgInfo {
  orgId?: string;
  companyLogoUrl?: string;
  otpInstructions?: string;
  name?: string;
  redirectUrl?: string;
  termsAndConditions?: TermsAndConditions;
}

export const LOCAL_STORAGE_ORG_INFO = "intuitBankFeedRutterLinkOrgInfo";

type IntuitBankFeedsCommonLayoutProps = {
  companyLogoUrl?: string;
  name?: string;
  errorMessage?: string;
  children: React.ReactNode;
};

const IntuitBankFeedsCommonLayout: React.FC<
  IntuitBankFeedsCommonLayoutProps
> = ({ errorMessage, children }) => {
  return (
    <div className="fixed inset-0 z-50 flex flex-col justify-center items-center p-14 box-border">
      <div className="w-full max-w-lg">
        {children}

        {errorMessage && (
          <p className="mt-1 font-thin text-red-500 text-center mb-[24px]">
            {errorMessage}
          </p>
        )}
        <footer className="flex justify-center items-center text-[#697386] gap-4 text-xs sm:text-sm mb-[24px] mt-[16px]">
          <div className="flex gap-1.5 items-center font-semibold">
            <span>Powered by</span>
            <a
              href="https://www.rutter.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={RutterSVG} alt="Rutter logo" className="w-14 sm:w-16" />
            </a>
          </div>
          <div className="w-[1.5px] h-4 bg-[#697386]" />
          <div className="flex gap-2 font-semibold">
            <a
              href="https://drive.google.com/file/d/1J77WNI4JS_WjFiXn9NgQVBy_N_unqpCn/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
            <a
              href="https://rutterdocs.s3.us-east-2.amazonaws.com/End-user+Privacy+Policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default IntuitBankFeedsCommonLayout;
