import { IoCheckmarkOutline } from "react-icons/io5";

export const HowIsYourDataUsed: React.FC<{
  title: string;
  description: string;
  hideIcon?: boolean;
  containerClassName?: string;
}> = ({ title, description, containerClassName, hideIcon, children }) => {
  return (
    <div className={`flex mb-[16px] gap-3 ${containerClassName}`}>
      <div className="me-1" hidden={hideIcon}>
        <IoCheckmarkOutline className="text-xl sm:text-2xl" />
      </div>
      <div>
        <h2 className="font-bold text-sm sm:text-base text-[#212430] mb-[4px]">
          {title}
        </h2>
        <p className="text-xs sm:text-sm text-slate-500 pe-8">{description}</p>
        <div className="text-xs sm:text-sm">{children}</div>
      </div>
    </div>
  );
};
