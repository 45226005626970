import Store, { Organization } from "../../stateStore";

export function shouldShowTextUnderConnect(org: Organization | null) {
  return (
    org?.additionalPrivacyPolicyLink ||
    org?.additionalTermsLink ||
    org?.platformPageConnectUnderText
  );
}

export default function TextUnderConnect() {
  const stateStore = Store.useStore();
  const org = stateStore.get("organization")!;
  const {
    additionalPrivacyPolicyLink,
    additionalTermsLink,
    platformPageConnectUnderText,
  } = org;

  if (platformPageConnectUnderText) {
    // highest priority
    return <div className="text-center">{platformPageConnectUnderText}</div>;
  }

  if (additionalTermsLink && !additionalPrivacyPolicyLink) {
    return (
      <div className="text-center">
        By connecting your store to {org.name}, you agree to the {org.name}{" "}
        <a href={additionalTermsLink} style={{ textDecoration: "underline" }}>
          Terms & Conditions
        </a>
      </div>
    );
  }
  if (!additionalTermsLink && additionalPrivacyPolicyLink) {
    return (
      <div>
        By connecting your store to {org.name}, you agree to the {org.name}{" "}
        <a
          href={additionalPrivacyPolicyLink}
          style={{ textDecoration: "underline" }}>
          Privacy Policy
        </a>
      </div>
    );
  }
  if (additionalPrivacyPolicyLink && additionalTermsLink) {
    return (
      <div className="text-center">
        By connecting your store to {org.name}, you agree to the {org.name}{" "}
        <a href={additionalTermsLink} style={{ textDecoration: "underline" }}>
          Terms & Conditions{" "}
        </a>
        and{" "}
        <a
          href={additionalPrivacyPolicyLink}
          style={{ textDecoration: "underline" }}>
          {" "}
          Privacy Policy
        </a>
      </div>
    );
  }

  return null;
}
