import * as amplitude from "@amplitude/analytics-browser";
import { Button } from "antd";
import qs from "qs";
import React, { useState } from "react";
import { FiCheck } from "react-icons/fi";
import { Route, Switch, useHistory } from "react-router-dom";
import url from "url-parse";
import BigCommerceAccount from "./bigcommerce/APIAccount";
import SelectPlatform from "./components/SelectPlatform";
import { HIGHBEAM_ORG_IDS, NILUS_ORG_IDS, SLOPE_ORG_IDS } from "./constants";
import TokenBasedNetsuite from "./netsuite/TokenBasedAuth";
import { captureException } from "./sentry";
import ShopifyPrivate from "./shopify/PrivateApp";
import Store from "./stateStore";
import Alibaba from "./stores/Alibaba";
import Amazon from "./stores/Amazon";
import BigCommerce from "./stores/BigCommerce";
import Chargebee from "./stores/Chargebee";
import Dynamics365 from "./stores/Dynamics365";
import Ebay from "./stores/Ebay";
import Etsy from "./stores/Etsy";
import FNACAuth from "./stores/FNAC";
import Freshbooks from "./stores/Freshbooks";
import Gumroad from "./stores/Gumroad";
import Lazada from "./stores/Lazada";
import Magento from "./stores/Magento";
import MercadoLibre from "./stores/MercadoLibre";
import PaypalAuth from "./stores/Paypal";
import PrestaShop from "./stores/PrestaShop";
import QuickBooks from "./stores/QuickBooks";
import QuickbooksDesktop from "./stores/QuickbooksDesktop";
import Recurly from "./stores/Recurly";
import SageBusinessCloud from "./stores/SageBusinessCloud";
import SageIntacct from "./stores/SageIntacct";
import Shopee from "./stores/Shopee";
import ShoperAuth from "./stores/Shoper";
import Shopify from "./stores/Shopify";
import Shoplazza from "./stores/Shoplazza";
import Shopware from "./stores/Shopware";
import Square from "./stores/Square";
import Squarespace from "./stores/Squarespace";
import StripeAuth from "./stores/Stripe";
import TallyAuth from "./stores/Tally";
import Walmart from "./stores/Walmart";
import Wave from "./stores/Wave";
import Webflow from "./stores/Webflow";
import Wix from "./stores/Wix";
import WooCommerce from "./stores/WooCommerce";
import Xero from "./stores/Xero";
import Zohobooks from "./stores/Zohobooks";
import GenericRoute from "./stores/genericAuth/GenericRoute";
import { STORES } from "./utils";
import Sage50 from "./stores/Sage50";

type LinkProps = {
  customerName: string;
  customerAction: string;
  origin: string;
  nonce: string;
  clientSecret: string;
  organizationId: string;
  isReconnect?: boolean;
  onSuccess: (token: string) => any;
  onError: (error: string) => any;
  onCancel: () => any;
  platform?: STORES;
};

// Used to show the oauth2 link method for customers that have gotten approved for it by walmart
const WALMART_OAUTH2_ORGS: string[] = [
  "aaac789f-d86b-4cf6-96eb-6b18b857ea35", // Airwallex Staging
  "a3b9a083-b22f-4aeb-bce0-0b40566b56f0", // Airwallex Production
  "2f4b6f5a-b2ed-40e1-bc4b-55e9858e5241", // Payoneer production
];

export function buildCustomRedirectUrl(
  customRedirectUrl: string,
  queryParams: object,
) {
  try {
    const parsedUrl = new url(customRedirectUrl, {}, true);
    if (parsedUrl.query) {
      return `${parsedUrl.origin?.includes("https") ? "" : "https://"}${
        parsedUrl.origin === "null" ? "" : parsedUrl.origin
      }${parsedUrl.pathname}?${qs.stringify({
        ...(parsedUrl.query as any),
        ...queryParams,
      })}`;
    } else {
      return `${
        parsedUrl.origin?.includes("https") ? "" : "https://"
      }${customRedirectUrl}?${qs.stringify({
        ...queryParams,
      })}`;
    }
  } catch (e) {
    throw e;
  }
}

function RutterLinkWrapper(props: LinkProps) {
  return <RutterLink {...props} />;
}

function RutterLink(props: LinkProps) {
  const { clientSecret } = props;
  const [store, setStore] = useState((props.platform as STORES) || "");
  const stateStore = Store.useStore();
  const platforms = stateStore.get("platforms");
  const origin = stateStore.get("origin");
  const org = stateStore.get("organization");
  const nonce = stateStore.get("nonce");
  const history = useHistory();
  const customUrl = stateStore.get("customRedirectUrl");
  const autoPlatform = stateStore.get("autoPlatform");
  const organization = stateStore.get("organization");
  const authenticationConfigs = stateStore.get("authenticationConfigs");
  const isSandbox = stateStore.get("isSandbox");

  function parseAuthConfig(jsonConfig: any) {
    if (!jsonConfig || !Array.isArray(jsonConfig)) {
      // Handle the case when jsonConfig is not an array
      return [];
    }
    return jsonConfig.map((config: any) => {
      // Create a new object with the parsed values
      const parsedObject = {
        platform: config.platform,
        formattedPlatformName: config.formattedPlatformName,
        type: config.type,
        iconUrl: config.iconUrl,
        frontendComponents: config.frontendComponents,
      };
      return parsedObject;
    });
  }
  const parsedConfigs = parseAuthConfig(authenticationConfigs);

  React.useEffect(() => {
    // This will disable the ability to go back to the disclaimer/palette pages
    // if the customer directs them *directly* to a platform
    const handleBack = (e: PopStateEvent) => {
      history.go(1);
    };

    if (autoPlatform) {
      window.addEventListener("popstate", handleBack);
    }

    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, [autoPlatform, history]);

  React.useEffect(() => {
    if (autoPlatform) {
      switch (autoPlatform.toLowerCase()) {
        case STORES.SHOPIFY:
        case "shopify":
          history.push("/shopify");
          break;
        case "etsy":
        case STORES.ETSY:
          history.push("/etsy");
          break;
        case "bigcommerce":
        case "big_commerce":
        case STORES.BIG_COMMERCE:
          history.push("/bigcommerce");
          break;
        case "square":
        case STORES.SQUARE:
          history.push("/square");
          break;
        case "squarespace":
        case STORES.SQUARESPACE:
          history.push("/squarespace");
          break;
        case "wix":
        case STORES.WIX:
          history.push("/wix");
          break;
        case "magento":
        case STORES.MAGENTO:
          history.push("/magento");
          break;
        case "woocommerce":
        case "woo_commerce":
        case STORES.WOO_COMMERCE:
          history.push("/woocommerce");
          break;
        case "amazon":
        case STORES.AMAZON:
          history.push("/amazon");
          break;
        case "ebay":
        case STORES.EBAY:
          history.push("/ebay");
          break;
        case "prestashop":
        case STORES.PRESTASHOP:
          history.push("/prestashop");
          break;
        case "recurly":
        case STORES.RECURLY:
          history.push("/recurly");
          break;
        case "stripe":
        case STORES.STRIPE:
          history.push("/stripe");
          break;
        case "paypal":
        case STORES.PAYPAL:
          history.push("/paypal");
          break;
        case STORES.SHOPEE:
        case "shopee":
          history.push("/shopee");
          break;
        case STORES.SHOPER:
        case "shoper":
          history.push("/shoper");
          break;
        case STORES.SHOPWARE:
        case "shopware":
          history.push("/shopware");
          break;
        case "fnac":
          history.push("/fnac");
          break;
        case STORES.LAZADA:
        case "lazada":
          history.push("/lazada");
          break;
        case STORES.FRESHBOOKS:
        case "freshbooks":
          history.push("/freshbooks");
          break;
        case STORES.QUICKBOOKS:
        case "quickbooks":
          history.push("/quickbooks");
          break;
        case STORES.QUICKBOOKS_DESKTOP:
        case "quickbooks_desktop":
          history.push("/quickbooks_desktop");
          break;
        case STORES.XERO:
        case "xero":
          history.push("/xero");
          break;
        case STORES.MERCADOLIBRE:
        case "mercadoLibre":
          history.push("/mercadoLibre");
          break;
        case STORES.NETSUITE:
        case "netsuite":
          history.push("/netsuite");
          break;
        case STORES.ZOHOBOOKS:
        case "zohobooks":
          history.push("/zohobooks");
          break;
        case STORES.SAGE_BUSINESS_CLOUD:
        case STORES.SAGE_BUSINESS_CLOUD.toLowerCase():
        case "sagebusinesscloud":
          history.push("/sagebusinesscloud");
          break;
        case STORES.CHARGEBEE:
        case "chargebee":
          history.push("/chargebee");
          break;
        case STORES.SAGE_INTACCT:
        case STORES.SAGE_INTACCT.toLowerCase():
        case "sageintacct":
          history.push("/sageintacct");
          break;
        case STORES.GUMROAD:
        case "gumroad":
          history.push("/gumroad");
          break;
        case STORES.WAVE:
        case "wave":
          history.push("/wave");
          break;
        case "alibaba":
          history.push("/alibaba");
          break;
        case "dynamics365":
        case STORES.DYNAMICS365:
          history.push("/dynamics365");
          break;

        case STORES.SHOPLAZZA:
        case "shoplazza":
          history.push("/shoplazza");
          break;
        default:
          // something
          history.push(`/${autoPlatform.toLowerCase()}`);
      }
    }
  }, [autoPlatform, history]);

  const closePlaid = () => {
    try {
      // post a message to cross-domain origin...
      if (window.opener) {
        window.opener.postMessage(
          JSON.stringify({
            nonce,
            type: "EXIT",
          }),
          origin,
        );
      }
    } catch (e) {
      captureException(e);
    }
    props.onCancel();
  };
  const goBack = () => {
    history.goBack();
    // setStep(step - 1);
  };

  const completeAuth = (publicToken: string) => {
    // cancel iframe gtfo out of here
    try {
      // post a message to cross-domain origin, this is if Link is in a popup
      if (window.opener) {
        window.opener.postMessage(
          JSON.stringify({
            publicToken,
            nonce,
            type: "SUCCESS",
          }),
          origin,
        );
      }
      // Now we want to send to the success screen, or custom URL if provided... with the public token
      if (customUrl) {
        const redirect = buildCustomRedirectUrl(customUrl, {
          public_token: publicToken,
        });
        window.location.replace(redirect);
      } else {
        // go to link success screen
        history.push("/complete");
      }
    } catch (e) {
      captureException(e);
      history.push("/error");
    }
  };

  function renderPrivacyPolicy() {
    if (NILUS_ORG_IDS.includes(organization?.id ?? "")) {
      // Nilus hide the privacy policy
      return null;
    }
    let privacyPolicyLink =
      "https://rutterdocs.s3.us-east-2.amazonaws.com/End-user+Privacy+Policy.pdf";
    if (HIGHBEAM_ORG_IDS.includes(organization?.id ?? "")) {
      privacyPolicyLink =
        "https://rutterdocs.s3.us-east-2.amazonaws.com/Highbeam+Privacy+Policy.pdf";
    }
    if (SLOPE_ORG_IDS.includes(organization?.id ?? "")) {
      privacyPolicyLink =
        "https://rutterdocs.s3.us-east-2.amazonaws.com/End-user+Privacy+Policy+-+Slope.pdf";
    }
    return (
      <div
        className="text-center pt-2"
        style={{ fontSize: "0.8rem", color: "#949494" }}>
        By selecting "Continue" you agree to the{" "}
        <a target="_blank" href={privacyPolicyLink} rel="noreferrer">
          Rutter End User Privacy Policy
        </a>
        {org?.additionalPrivacyPolicyLink && (
          <span>
            {" "}
            & the{" "}
            <a
              target="_blank"
              href={org.additionalPrivacyPolicyLink}
              rel="noreferrer">
              {org.name} Privacy Policy
            </a>
          </span>
        )}
      </div>
    );
  }

  const { linkLogoUrl, linkText } = organization || {};

  const renderStep = (currentStep: number) => {
    if (currentStep === 0) {
      return (
        <div
          style={{ padding: "3.2rem", height: "100%", position: "relative" }}>
          <div className="flex flex-column items-center justify-center pb-4">
            {linkLogoUrl ? (
              <img
                style={{ height: 80, marginBottom: 20 }}
                src={linkLogoUrl}
                alt=""
              />
            ) : (
              <img
                style={{ width: 48, height: 48, marginBottom: 20 }}
                alt=""
                src={
                  "https://rutterpublicimages.s3.us-east-2.amazonaws.com/rutternewnewlogo.svg"
                }></img>
            )}
          </div>
          <div className="text-xl text-center">
            {linkText ? (
              <div className="text-center">{linkText}</div>
            ) : (
              <>
                <span className="font-semibold">{props.customerName}</span> uses{" "}
                <span className="font-semibold">Rutter</span>&nbsp;to{"\n"}
                <div className="text-center">{props.customerAction}</div>
              </>
            )}
          </div>
          <div className="mt-8">
            <div>
              <div className="flex items-center">
                <FiCheck size={30} color="#62DAFF"></FiCheck>
                <div>
                  <div className="ml-3 text-lg">Secure</div>
                  <div className="ml-3">
                    Your data is handled securely with end-to-end encryption.
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="flex items-center">
                <FiCheck size={30} color="#62DAFF"></FiCheck>
                <div>
                  <div className="ml-3 text-lg">Private</div>
                  <div className="ml-3">
                    Your data will never be shared without your permission.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "42px",
              marginBottom: ".8rem",
              marginRight: ".8rem",
              marginLeft: ".8rem",
            }}
            className="">
            <Button
              onClick={() => {
                amplitude.track("Disclaimer Screen Continue Clicked");
                if (platforms?.length === 1) {
                  history.push(`/${platforms[0].name.toLowerCase()}`);
                } else {
                  // Add query parameters to allow refreshing of the page and everything still works
                  history.push("/platforms" + history.location.search);
                }
              }}
              style={{ marginTop: "auto" }}
              className="w-full"
              type="primary"
              size="large">
              Continue
            </Button>
            {renderPrivacyPolicy()}
          </div>
        </div>
      );
    } else if (currentStep === 1) {
      return <SelectPlatform platforms={platforms} setStore={setStore} />;
    } else if (currentStep === 2) {
      // Note:  This is tech debt.  Move to using the Route/Switch below instead.
      switch (store) {
        case STORES.SHOPIFY:
          return (
            <Shopify
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.MAGENTO:
          return (
            <Magento
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.WOO_COMMERCE:
          return (
            <WooCommerce
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.BIG_COMMERCE:
          return (
            <BigCommerce
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.WIX:
          return (
            <Wix
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.SQUARESPACE:
          return (
            <Squarespace
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.SQUARE:
          return (
            <Square
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.AMAZON:
          return (
            <Amazon
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.EBAY:
          return (
            <Ebay
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        case STORES.FRESHBOOKS:
          return (
            <Freshbooks
              clientSecret={clientSecret}
              onBack={goBack}
              onCancel={closePlaid}
              onComplete={completeAuth}
            />
          );
        // Note: DO NOT ADD MORE  This is tech debt.  Move to using the Route/Switch below instead.
        default:
          return <div />;
      }
    } else {
      return null;
    }
  };

  const generateRouteBlock = (config: any) => (
    <Route exact path={"/" + config.platform.toLowerCase()}>
      <GenericRoute
        clientSecret={clientSecret}
        onBack={goBack}
        onCancel={closePlaid}
        onComplete={completeAuth}
        config={config}
      />
    </Route>
  );

  return (
    <Switch>
      <Route exact path="/bigcommerceapiaccount">
        <BigCommerceAccount
          clientSecret={clientSecret}
          onBack={goBack}
          onCancel={closePlaid}
          onComplete={completeAuth}></BigCommerceAccount>
      </Route>
      {!WALMART_OAUTH2_ORGS.includes(organization?.id ?? "") && (
        <Route exact path="/walmart">
          <Walmart
            clientSecret={clientSecret}
            onBack={goBack}
            onCancel={closePlaid}
            onComplete={completeAuth}
          />
        </Route>
      )}
      <Route exact path="/shopifyprivate">
        <ShopifyPrivate
          clientSecret={clientSecret}
          onBack={goBack}
          onCancel={closePlaid}
          onComplete={completeAuth}></ShopifyPrivate>
      </Route>
      <Route exact path="/netsuite">
        <TokenBasedNetsuite
          clientSecret={clientSecret}
          onBack={goBack}
          onCancel={closePlaid}
          onComplete={completeAuth}
        />
      </Route>
      <Route exact path="/sageintacct">
        <SageIntacct
          clientSecret={clientSecret}
          onBack={goBack}
          onCancel={closePlaid}
          onComplete={completeAuth}
        />
      </Route>
      <Route exact path="/quickbooks_desktop">
        <QuickbooksDesktop
          clientSecret={clientSecret}
          onBack={goBack}
          onCancel={closePlaid}
          onComplete={completeAuth}
        />
      </Route>
      <Route exact path="/quickbooksdesktop">
        <QuickbooksDesktop
          clientSecret={clientSecret}
          onBack={goBack}
          onCancel={closePlaid}
          onComplete={completeAuth}
        />
      </Route>
      <Route exact path="/sage50">
        <Sage50
          clientSecret={clientSecret}
          onBack={goBack}
          onCancel={closePlaid}
          onComplete={completeAuth}
        />
      </Route>
      <Route>
        <div
          style={{
            height: 608,
            width: org?.whitelabelOptions?.maxWidth
              ? org?.whitelabelOptions?.maxWidth
              : 360,
            backgroundColor: "white",
            borderRadius: 8,
            overflowX: "hidden",
            overflowY: "auto",
          }}>
          <Switch>
            <Route exact path={`/platforms`}>
              {renderStep(1)}
            </Route>
            <Route exact path="/auth">
              {renderStep(2)}
            </Route>
            {parsedConfigs.map((config: any) => generateRouteBlock(config))}
            <Route exact path="/shopify">
              <Shopify
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/squarespace">
              <Squarespace
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/magento">
              <Magento
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/woocommerce">
              <WooCommerce
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/bigcommerce">
              <BigCommerce
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/wix">
              <Wix
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/square">
              <Square
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/amazon">
              <Amazon
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/ebay">
              <Ebay
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/etsy">
              <Etsy
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/prestashop">
              <PrestaShop
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/stripe">
              <StripeAuth
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/paypal">
              <PaypalAuth
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/shopee">
              <Shopee
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/shoper">
              <ShoperAuth
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/webflow">
              <Webflow
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/shopware">
              <Shopware
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/fnac">
              <FNACAuth
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/freshbooks">
              <Freshbooks
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/lazada">
              <Lazada
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/quickbooks">
              <QuickBooks
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/zohobooks">
              <Zohobooks
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/xero">
              <Xero
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/sagebusinesscloud">
              <SageBusinessCloud
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/recurly">
              <Recurly
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/mercadoLibre">
              <MercadoLibre
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/chargebee">
              <Chargebee
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>

            <Route exact path="/gumroad">
              <Gumroad
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/shoplazza">
              <Shoplazza
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/wave">
              <Wave
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/alibaba">
              <Alibaba
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/dynamics365">
              <Dynamics365
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}
              />
            </Route>
            <Route exact path="/tally">
              <TallyAuth
                clientSecret={clientSecret}
                onBack={goBack}
                onCancel={closePlaid}
                onComplete={completeAuth}></TallyAuth>
            </Route>
            <Route>{renderStep(0)}</Route>
          </Switch>
        </div>
      </Route>
    </Switch>
  );
}

export default RutterLinkWrapper;
