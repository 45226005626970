import { Input } from "antd";
import Link from "antd/lib/typography/Link";

type UserInputConfig = {
  type: string;
  title?: string;
  text?: string;
  link?: string;
  linkText?: string;
  promptText?: string;
  userInputFieldName?: string;
};
type UserInputComponentProps = {
  config: UserInputConfig;
  userInputField: string;
  setUserInputField: (value: string) => void;
};
function UserInputComponent(props: UserInputComponentProps) {
  const { config, userInputField, setUserInputField } = props;

  return (
    <div>
      {config.title && <div className="font-semibold">{config.title}</div>}
      {config.text && <div className="text-sm ">{config.text}</div>}

      {config.linkText && config.link && (
        <div className="text-sm ">
          <Link href={config.link} target="_blank">
            {config.linkText}
          </Link>
        </div>
      )}
      {config.userInputFieldName && (
        <div className="mt-2">
          <Input
            placeholder={config.promptText}
            value={userInputField}
            onChange={(e) => setUserInputField(e.target.value)}
          />
        </div>
      )}
      <div style={{ marginBottom: 16 }}></div>
    </div>
  );
}
export default UserInputComponent;
