import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import axios from "axios";
import qs from "query-string";
import { useEffect, useState } from "react";
import { RAMP_ORG, UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Square(props: Props) {
  const { onComplete, clientSecret } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const store = Store.useStore();
  const isSandbox = store.get("isSandbox");
  const orgName = store.get("orgName");
  const organizationId = store.get("orgId");
  const autoPlatform = store.get("autoPlatform");
  const stateStore = Store.useStore();

  useEffect(() => {
    if (isRampOrg()) {
      return;
    }
    if (isSandbox) {
      handleFakeComplete();
    } else {
      handleComplete();
    }
  }, []);

  const isRampOrg = () => RAMP_ORG.includes(orgName);

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  const handleComplete = () => {
    // redirect to oauth
    setLoading(true);
    const q = qs.stringify({
      ...extractLinkParamsFromState(stateStore),
    });
    persistLinkState(STORES.PAYPAL, stateStore.getState());
    const url = `${getBackendUrl()}/paypal/internal/authorize?${q}`;
    window.location.replace(url);
  };

  const handleFakeComplete = () => {
    // skip oauth and get fake credentials
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.PAYPAL,
        clientId: clientSecret,
        username: "user_good",
        password: "pass_good",
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        // NEED OT PASS THIS BACK SOMEHOW
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  if (!isRampOrg()) {
    return <></>;
  }

  return (
    <div style={{ padding: "1.6rem" }}>
      {!autoPlatform && (
        <div style={{ display: "flex" }} className="">
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        </div>
      )}
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/logos/paypal-logo.png"
          alt=""
        />
      </div>
      <div className="text-center pb-4">
        Connect your PayPal account with {orgName} by clicking the button below.
      </div>
      {isSandbox ? (
        <Button
          type="primary"
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test Paypal"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect PayPal"}
        </Button>
      )}
      {isRampOrg() ? (
        <div className="my-2">
          By clicking "Connect Paypal", you authorize Ramp to access this
          information maintained by third-parties with which you maintain
          accounts, on your behalf as your agent, and you expressly authorize
          such third parties and Rutter to disclose your information to us.
        </div>
      ) : null}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Square;
