import { Button, Select } from "antd";
import qs from "query-string";
import { useState } from "react";
import { useHistory } from "react-router";
import { getBackendUrl, STORES } from "../utils";
// import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  platform: STORES;
};

/*
This component allows people to choose which company/organization in an accounting system to connect to Rutter
The most important thing is that the query parameters contain a list of companyNames (so the user can select which one)
and an array of credentialIds (corresopnding to real Credentials in our backend that we've already created for each option)
The credentialsId and companyNames arrays are related by index, so companyNames[0] corresponds to credentialsId[0]
The select component just asks the user to choose which company to connect, and redirects again to the backend.
*/
function CompanySelect(props: Props) {
  const [credentialId, setCredentialId] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { platform } = props;

  const history = useHistory();
  const { search } = history.location;

  // With the new query we will expect one credentialId and an array of companyIds instead of multiple credentialIds.
  const usingCompanyId =
    search.includes("credentialId=") && search.includes("companyIds=");

  // The new URL will use a comma separated list array instead of re-specifying the query param.
  const arrayFormat = usingCompanyId ? "comma" : "none";
  const parsedSearch: any = qs.parse(search, { arrayFormat });

  const { state } = parsedSearch;
  let companyNames = parseArray(parsedSearch.companyNames);

  let companyOrCredentialIds: string[] = [];
  if (usingCompanyId) {
    companyOrCredentialIds = parseArray(parsedSearch.companyIds);
  } else {
    companyOrCredentialIds = parseArray(parsedSearch.credentialIds);
  }

  if (companyNames == null || companyNames.length === 0) {
    history.replace("/error");
    return null;
  }

  const setCompanyOrCredentialId = (val: string) => {
    if (usingCompanyId) {
      setCompanyId(val);
      setCredentialId(parsedSearch.credentialId);
    } else {
      setCredentialId(val);
    }
  };

  const handleComplete = () => {
    setIsLoading(true);
    const q = qs.stringify({
      state,
      // Prefer `credentialId` for casing, but keep `credentialid` for backwards compatibility.
      credentialid: credentialId,
      credentialId,
      companyId,
    });
    let url;
    switch (platform) {
      case STORES.DYNAMICS365:
        url = `${getBackendUrl()}/dynamics/finish-company-select?${q}`;
        break;
      case STORES.ZOHOBOOKS:
        url = `${getBackendUrl()}/zohobooks/finish-company-select?${q}`;
        break;
      default:
        history.replace("/error");
        return null;
    }
    window.location.href = url;
    setIsLoading(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div style={{ width: 800 }}>
        <div className="font-semibold">
          Select the Company you would like to connect:
        </div>

        <Select
          showSearch
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
          placeholder="Select Company"
          onSelect={(val: any) => setCompanyOrCredentialId(val as string)}
          defaultValue={undefined}>
          {companyNames.map((name, index) => {
            return (
              <Select.Option key={name} value={companyOrCredentialIds[index]}>
                {name}
              </Select.Option>
            );
          })}
        </Select>
        <Button
          type="primary"
          block
          disabled={isLoading || !credentialId}
          onClick={handleComplete}>
          Connect
        </Button>
      </div>
    </div>
  );
}

function parseArray(param: any): string[] {
  if (typeof param === "string") {
    return param.split(",");
  }
  if (Array.isArray(param)) {
    return param;
  }
  return [];
}

export default CompanySelect;
