import * as amplitude from "@amplitude/analytics-browser";
import { Button, Input } from "antd";
import axios from "axios";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IntuitBankFeedsConsentHeader } from "../../components/intuitBankFeeds/header";
import { HowIsYourDataUsed } from "../../components/intuitBankFeeds/howIsYourDataUsed";
import { TermsAndConditionsText } from "../../components/intuitBankFeeds/termsAndConditions";
import SpinnerLoader from "../../components/Loading/SpinnerLoader";
import { getBackendUrl } from "../../utils";
import IntuitBankFeedsCommonLayout, {
  LOCAL_STORAGE_ORG_INFO,
  OrgInfo,
} from "./IntuitBankFeedsCommon";

const IntuitBankFeedsAuth: React.FC = () => {
  const [isFetchingOrgInfo, setIsFetchingOrgInfo] = useState<boolean>(true);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { login_challenge } = parsedSearch;

  const [password, setPassword] = useState<string>("");
  const [orgInfo, setOrgInfo] = useState<OrgInfo>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showDefaultLogin, setShowDefaultLogin] = useState<boolean>(false);
  const [showInstitutionMessage, setShowInstitutionMessage] =
    useState<boolean>(false);

  useEffect(() => {
    axios
      .get(
        `${getBackendUrl()}/intuit_bank_feeds_connector/auth/org?login_challenge=${login_challenge}`,
      )
      .then((response) => {
        if (typeof response.data !== "object") {
          setOrgInfo({ orgId: response.data });
        } else {
          const {
            orgId,
            companyLogoUrl,
            otpInstructions,
            name,
            redirectUrl,
            termsAndConditions,
          } = response.data;
          const orgInfo: OrgInfo = {
            orgId,
            companyLogoUrl,
            otpInstructions,
            name,
            redirectUrl,
            termsAndConditions,
          };
          setOrgInfo(orgInfo);
          localStorage.setItem(LOCAL_STORAGE_ORG_INFO, JSON.stringify(orgInfo));
          amplitude.setGroup("orgId", orgId);
          amplitude.track("Intuit Bank Feeds: Auth Page Loaded");

          setShowDefaultLogin(!redirectUrl);
        }
      })
      .catch((err) => {}) // No action on error - let a later step fail.
      .finally(() => {
        setIsFetchingOrgInfo(false);
      });
  }, [login_challenge, setOrgInfo, setIsFetchingOrgInfo]);

  const handleCompleteSubmit = async () => {
    amplitude.track("Intuit Bank Feeds: Auth Submit Clicked");
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${getBackendUrl()}/intuit_bank_feeds_connector/auth/login?challenge=${login_challenge}&otp=${password}&orgId=${
          orgInfo.orgId
        }`,
      );
      amplitude.track(
        "Intuit Bank Feeds: Auth OTP Success, redirecting to Ory",
      );

      window.location.replace(response.data);
    } catch (e: any) {
      amplitude.track("Intuit Bank Feeds: Auth OTP Error");
      setErrorMessage(
        e.response?.data?.error_message ?? "Something went wrong.",
      );
      setIsSubmitting(false);
    }
  };

  const handleCompleteCancel = () => {
    amplitude.track("Intuit Bank Feeds: Auth Cancel Clicked");
    history.push({
      pathname: "/intuit_bank_feeds_cancelled",
      state: { orgInfo },
    });
  };

  const continueToLoginHandler = () => {
    if (!orgInfo.redirectUrl) return setShowDefaultLogin(true);

    setIsRedirecting(true);
    setShowInstitutionMessage(true);
    window.location.replace(orgInfo.redirectUrl);
  };

  return (
    <IntuitBankFeedsCommonLayout
      companyLogoUrl={orgInfo.companyLogoUrl}
      name={orgInfo.name}
      errorMessage={errorMessage}
    >
      {isRedirecting || isFetchingOrgInfo ? (
        <div className="h-96 flex items-center justify-center">
          <div className={showInstitutionMessage ? "h-full" : ""}>
            <SpinnerLoader size={60} className="mb-[30px]" />

            {showInstitutionMessage && (
              <div className="text-center">
                <h1 className="font-bold text-xl text-[#212430] mb-[8px]">
                  Contacting {orgInfo?.name ?? "Financial Institution"}
                </h1>
                <p className="text-slate-500 text-center text-sm mb-[32px]">
                  Please wait while a secure connection is established.
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {showDefaultLogin ? (
            <>
              <h2 className="text-lg mt-4">One-Time Password (OTP)</h2>
              <div className="mt-1 font-thin" style={{ fontWeight: 300 }}>
                {orgInfo.otpInstructions ??
                  "Please enter the one-time password (OTP) provided to you by your financial institution. If you don't see it, check your financial institution's documentation for connecting to Intuit Bank Feeds."}
              </div>
              <div className="mt-2">
                <Input
                  placeholder=""
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <Button
                className="        
                  mt-[16px]
                  flex 
                  justify-center
                  items-center 
                  py-5
                  rounded-md
                  font-medium
                  bg-[#fff] border-slate-300 
                  hover:bg-[#F9FAFC] 
                  hover:border-[#212430] hover:text-[#212430]
                  border-1.5 
                "
                block
                onClick={handleCompleteCancel}
              >
                Cancel
              </Button>
              <Button
                className="
                   flex 
                    justify-center 
                    items-center 
                    py-5 mt-2
                    rounded-md 
                    text-white
                    font-medium
                    bg-[#212430] 
                    hover:bg-[#020211] hover:text-[#fff] hover:border-[#020211]
                    mb-[32px]
                "
                block
                disabled={!password}
                loading={isSubmitting}
                onClick={handleCompleteSubmit}
              >
                Submit
              </Button>
            </>
          ) : (
            <DataSharing
              orgInfo={orgInfo}
              cancelHandler={handleCompleteCancel}
              continueToLoginHandler={continueToLoginHandler}
            />
          )}
        </>
      )}
    </IntuitBankFeedsCommonLayout>
  );
};

export default IntuitBankFeedsAuth;

const DataSharing = ({
  orgInfo,
  cancelHandler,
  continueToLoginHandler,
}: {
  orgInfo: OrgInfo;
  cancelHandler: () => void;
  continueToLoginHandler: () => void;
}) => {
  return (
    <>
      <IntuitBankFeedsConsentHeader
        title={
          orgInfo?.name ? (
            <span>
              {orgInfo?.name} securely connects to your <br /> accounts while
              protecting your data
            </span>
          ) : (
            <span>
              Securely connect to your <br /> account while protecting your data
            </span>
          )
        }
        financialInstitutionLogo={orgInfo?.companyLogoUrl ?? undefined}
      />
      <HowIsYourDataUsed
        title="How your data is used"
        description={`When you link your ${
          orgInfo?.name ?? ""
        } account, you'll be able to access your transactions in Intuit products for quick and easy reconciliation.`}
        hideIcon={true}
        containerClassName="mt-[16px]"
      />
      <HowIsYourDataUsed
        title="What data is shared"
        description="Intuit will access your data until you withdraw consent. You can unlink your account any time, and your credentials won’t be shared."
        hideIcon={true}
      >
        <ul className="list-disc list-inside mt-[12px] ps-3">
          <li className="text-slate-500 marker:text-slate-500">
            Account Summary Information
          </li>
          <li className="text-slate-500 marker:text-slate-500">Transactions</li>
        </ul>
      </HowIsYourDataUsed>
      <Button
        className="
        mt-[16px]
        flex 
        justify-center
        items-center 
        py-4 sm:py-5
        rounded-md
        font-medium
        bg-[#fff] border-slate-300 
        hover:bg-[#F9FAFC] 
        hover:border-[#212430] hover:text-[#212430] 
      "
        block
        onClick={cancelHandler}
      >
        Cancel
      </Button>
      <Button
        className={` 
        flex 
        justify-center 
        items-center 
        py-4 sm:py-5 
        mt-2
        ${!orgInfo?.termsAndConditions && "mb-[32px]"}
        rounded-md 
        text-white
        font-medium
        bg-[#212430] 
        hover:bg-[#020211] hover:text-[#fff] hover:border-[#020211]
      `}
        block
        onClick={continueToLoginHandler}
      >
        Continue to login
      </Button>
      {orgInfo?.termsAndConditions && (
        <div className="mt-[8px] px-14 text-xs sm:text-sm">
          <TermsAndConditionsText
            termsAndConditions={orgInfo.termsAndConditions}
          />
        </div>
      )}
    </>
  );
};
