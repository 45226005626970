import { TermsAndConditions } from "../../accounting/intuitBankFeeds/IntuitBankFeedsCommon";

export const TermsAndConditionsText = ({
  termsAndConditions,
}: {
  termsAndConditions?: TermsAndConditions;
}) => {
  if (!termsAndConditions?.defaultUrl) {
    return null; // If defaultUrl doesn't exist, return nothing
  }

  return (
    <p
      style={{ fontWeight: 400, textAlign: "center" }}
      className="text-slate-500"
    >
      By selecting "Continue", you agree to{" "}
      <a
        href={termsAndConditions.defaultUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#1890ff" }}
      >
        Connect Account Terms and Conditions
      </a>
      {/* Conditionally add "and the..." part if additional.url exists */}
      {termsAndConditions?.additionalUrl ? (
        <>
          {" "}
          and the{" "}
          <a
            href={termsAndConditions.additionalUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#1890ff" }}
          >
            {termsAndConditions?.additionalUrlTitle ?? "additional terms"}
          </a>
          .
        </>
      ) : (
        "."
      )}
    </p>
  );
};
