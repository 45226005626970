import { Button, Result } from "antd";
import qs from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
import { captureException } from "../sentry";

function LinkComplete() {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch = qs.parse(search);
  const { redirect_uri } = parsedSearch;
  const [isLoading, setLoading] = React.useState(true);
  const [isLink, setIsLink] = React.useState(false);
  const [isRedirecting, setIsRedirecting] = React.useState(false);

  React.useEffect(() => {
    // get the localstorage if any
    let nonce, origin, platform;
    const { public_token } = parsedSearch;

    const data = localStorage.getItem("rutterlinkstate");
    localStorage.clear();

    if (!data) {
      // no data this must have not been from link, so we need to redirect to the organization page....
      const { email, public_token, ...rest } = parsedSearch;
      const queryString = qs.stringify({
        email,
        public_token,
        ...rest,
      });
      if (!redirect_uri) {
        window.location.replace(`/error`);
      } else {
        setIsRedirecting(true);
        window.location.replace(`${redirect_uri as string}?${queryString}`);
      }
      setIsLink(false);
    } else {
      if (redirect_uri) {
        const { email, public_token, ...rest } = parsedSearch;
        const queryString = qs.stringify({
          email,
          public_token,
          ...rest,
        });
        setIsRedirecting(true);
        window.location.replace(`${redirect_uri as string}?${queryString}`);
      }

      setIsLink(true);
      const parsedData = JSON.parse(data);
      const {
        currentPlatform,
        nonce: storageNonce,
        origin: storageOrigin,
      } = parsedData;
      nonce = storageNonce;
      origin = storageOrigin;
      platform = currentPlatform;
    }
    // post success no matter what if we can so customer knows
    if (public_token && nonce && origin) {
      if (platform === "BIG_COMMERCE" && window?.parent?.opener) {
        localStorage.removeItem("rutterlinkstate");
        window.parent.opener.postMessage(
          JSON.stringify({
            publicToken: public_token,
            nonce,
            type: "SUCCESS",
            platform,
          }),
          origin,
        );
      }
      setLoading(false);
    }
  }, []);

  const handleClose = () => {
    if (isLink) {
      try {
        window.parent.close();
      } catch (e) {
        captureException(e, {
          source: "bigcommerce/linkcomplete",
        });
        window.parent.close();
      }
    } else {
      // open new tab with the thing
      window.open(redirect_uri as string, "_blank");
    }
  };

  if (isRedirecting) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Redirecting you to {redirect_uri}...
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin></Spin>
      </div>
    );
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="success"
        title="Authentication Complete!"
        subTitle=""
        extra={[
          <Button type="primary" key="console" onClick={handleClose}>
            {isLink ? "Close Window" : "Complete Install"}
          </Button>,
        ]}
      />
    </div>
  );
}

export default LinkComplete;
