import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import MainContainer from "./container/MainContainer";
import Store from "./stateStore";
import "./tailwind.output.css";
import { isDev } from "./utils";
import { QueryClient, QueryClientProvider } from "react-query";

amplitude.init(
  isDev()
    ? "78b45c9d8e33600c0c8060918a0c5b03"
    : "7a121bb187213b0be08c200813bcba5f",
);
Sentry.init({
  dsn: "https://e2b2bb62e22b42079c92b3bb4afbe093@o271524.ingest.sentry.io/5602474",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  normalizeDepth: 8,
});

const queryClient = new QueryClient();

function App(props: any) {
  return (
    <Store.Container>
      <QueryClientProvider client={queryClient}>
        <MainContainer></MainContainer>
      </QueryClientProvider>
    </Store.Container>
  );
}

export default App;
